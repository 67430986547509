import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space } from 'antd';
import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  PROVIDER_TYPES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import {
  Blurhash,
  fileUpload,
  formValidatorRules
} from '../../../common/utils';
import { messageContext } from '../../../components/AppComponentContainer';
import ProgressBar from '../../../components/ProgressBar';
import history from '../../../historyData';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { SelectPlatForm } from '../../videos/components/FormInputs';
import { CREATE_ASSET, UPDATE_ASSET } from '../graphql/Mutations';
import { GET_ASSET, GET_UPLOAD_SIGNED_URL } from '../graphql/Queries';
import { FileUpload } from './components';

const initialValues = {
  title: '',
  description: '',
  icon: [],
  platform: null
};

const CommonIconForm = ({
  isModal,
  setShowModal,
  setNewAsset,
  isMenu = false
}) => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [form] = Form.useForm();
  const { iconId } = params;
  const isEdit = !!iconId;

  const [addEditIcon] = useMutation(isEdit ? UPDATE_ASSET : CREATE_ASSET);
  const [getSignedUrl] = useLazyQuery(GET_UPLOAD_SIGNED_URL, {
    fetchPolicy: 'network-only'
  });

  const [fetchImage, { loading: fetchingDetails }] = useLazyQuery(GET_ASSET, {
    fetchPolicy: 'network-only'
  });

  const showProgress = progress !== 0 && progress !== 100;

  useEffect(() => {
    if (isEdit && !!iconId) {
      fetchImage({
        variables: {
          where: {
            id: iconId
          }
        }
      }).then(({ data, error }) => {
        if (!error && data) {
          form.setFieldsValue({
            title: data?.asset?.title ?? '',
            description: data?.asset?.description ?? '',
            icon: [],
            platform: data?.asset?.platform
              ? {
                  label: data?.asset?.platform?.name,
                  value: data?.asset?.platform?.id
                }
              : null
          });
        }
      });
    }
  }, [isEdit, iconId, form]);

  const handleSubmit = async (values) => {
    setLoading(true);
    const { icon, platform, ...restValues } = values;

    const payload = {
      ...restValues,
      categoryKey: ASSET_CATEGORY.ICON
    };
    try {
      if (icon?.length) {
        const file = icon?.[0]?.originFileObj;
        const { data, error } = await getSignedUrl({
          variables: {
            data: {
              fileName: file?.name?.replace(/\s/g, '_'),
              contentType: file?.type,
              assetType: ASSET_CATEGORY?.ICON,
              platformId: platform?.value
            }
          }
        });
        if (error) throw Error(error);
        if (data && data?.getAssetUploadSignedUrl) {
          await fileUpload(
            data?.getAssetUploadSignedUrl?.signedUrl,
            file,
            setProgress
          );
          payload.key = data?.getAssetUploadSignedUrl?.key;
          payload.contentType = file?.type;
          payload.blurhash = await Blurhash.encode(file);
        }
      }
      addEditIcon({
        variables: {
          data: {
            ...payload,
            ...(!isEdit && {
              platformId: platform?.value
            })
          },
          ...(isEdit && {
            where: {
              id: iconId
            }
          })
        }
      }).then((response) => {
        setProgress(0);
        setLoading(false);
        if (isModal) {
          const asset = response?.data?.createAsset?.asset;
          setShowModal(false);
          setNewAsset({ asset: true, data: asset });
        } else {
          history.push(ROUTES?.ICONS);
        }
      });
    } catch (err) {
      setProgress(0);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    if (isModal) {
      setShowModal(false);
      setNewAsset(null);
    } else {
      history.push(ROUTES?.ICONS);
    }
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  };

  const checkForStroke = (svgContent) => {
    // Parse the SVG content and check for the stroke attribute
    // eslint-disable-next-line no-undef
    const parser = new DOMParser();
    const svgDoc = parser?.parseFromString(svgContent, 'image/svg+xml');
    const svgElement = svgDoc?.documentElement;
    const hasStroke = hasAttributeRecursive(svgElement, 'stroke');
    return hasStroke;
  };

  function hasAttributeRecursive(element, attribute) {
    if (element.hasAttribute(attribute)) {
      return true;
    }
    const { children } = element;
    for (let i = 0; i < children.length; i += 1) {
      if (hasAttributeRecursive(children[i], attribute)) {
        return true;
      }
    }

    return false;
  }

  return (
    <>
      <Form
        form={form}
        className="add-edit-form"
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleSubmit}
        disabled={isViewOnly || fetchingDetails}
      >
        <Form.Item
          label="Title"
          name="title"
          required
          rules={[
            formValidatorRules?.required('Please enter title!'),
            formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
          ]}
        >
          <Input placeholder="Enter title" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
        >
          <Input.TextArea placeholder="Enter description" />
        </Form.Item>
        <Form.Item
          name="platform"
          label="Platform"
          rules={[{ required: true, message: 'Platform is required' }]}
        >
          <SelectPlatForm
            disabled={isEdit}
            type={PROVIDER_TYPES.STORAGE}
            fullWidth
          />
        </Form.Item>
        <Form.Item
          name="icon"
          label="Icon"
          rules={[
            { required: !isEdit, message: 'Please select icon!' },
            {
              message:
                'Svg you uploaded contains stroke please reupload the one without strokes.',
              validator: async (_, value) => {
                const { originFileObj: file } = value?.[0] || {};
                if (file) {
                  try {
                    const svgContent = await readFileContent(file);
                    const hasStroke = checkForStroke(svgContent);
                    if (hasStroke) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise.reject('Some message here');
                    }
                    return Promise.resolve();
                  } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error('Error reading file:', error);
                  }
                }
              }
            }
          ]}
          getValueFromEvent={(e) => {
            if (isMenu && !e?.file?.url && e?.file?.type !== 'image/svg+xml') {
              messageContext?.destroy();
              messageContext?.error('You can only upload svg icon for menu');
              return [];
            }
            if (isArray(e)) {
              return e;
            }
            return e?.fileList;
          }}
          valuePropName="fileList"
        >
          <FileUpload
            maxCount={1}
            accept={isMenu ? '.svg' : '.png, .svg'}
            helperText={isMenu ? '.svg' : '.png, .svg'}
          />
        </Form.Item>
        {showProgress && (
          <Form.Item>
            <ProgressBar progress={progress} />
          </Form.Item>
        )}
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              {isAddEditAllowed && (
                <Button
                  loading={loading || fetchingDetails}
                  type="text"
                  htmlType="submit"
                  className="text-btn mr-8"
                  size="middle"
                >
                  Save
                </Button>
              )}
            </Form.Item>
            <Form.Item>
              <Button
                type="text"
                className="text-btn2"
                disabled={loading}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </>
  );
};

export default CommonIconForm;
