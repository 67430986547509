import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import LoaderComponent from '../../components/LoaderComponent';
import { GET_ANALYTICS_LINK } from '../analytics-module/graphql/Queries';

const Analytics = ({ match: { params } }) => {
  const { analyticsId } = params;
  const [fetchAnalyticLink, { data, loading }] = useLazyQuery(
    GET_ANALYTICS_LINK,
    {
      fetchPolicy: 'no-cache',
      variables: {
        where: {
          id: analyticsId
        }
      },
      onError() {}
    }
  );

  useEffect(() => {
    fetchAnalyticLink();
  }, []);

  if (loading) return <LoaderComponent />;

  return (
    <div className="h-full mt-24 mb-24">
      <div className="page-wrapper h-full">
        <div className="page-wrapper-body h-full">
          {data?.analyticLink?.value && (
            <div
              className="h-full"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: data?.analyticLink?.value
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Analytics;
