import { useMutation } from '@apollo/client';
import { Button, Select, Tag } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import {
  DONATION_TYPES,
  LIST_TYPES,
  PAYMENT_STATUS,
  STATUS_COLORS,
  SUBSCRIPTION_STATUS,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import Avatar from '../../components/Avatar';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import { CANCEL_DONATION } from './graphql/Mutations';
import { GET_DONATIONS } from './graphql/Queries';

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder,
  type
}) => ({
  filter: {
    skip: offset,
    search,
    limit,
    type
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ donationsAdmin }) => ({
  data: donationsAdmin?.donations ?? [],
  count: donationsAdmin?.count ?? 0
});

const Action = ({ id, paymentStatus, type, subscriptionStatus, refresh }) => {
  const [cancelDonation, { loading }] = useMutation(CANCEL_DONATION);

  const handleClick = () => {
    cancelDonation({
      variables: {
        id
      }
    }).then(() => {
      refresh();
    });
  };

  return (
    paymentStatus === PAYMENT_STATUS.SUCCESS &&
    type !== DONATION_TYPES.ONE_TIME &&
    subscriptionStatus !== SUBSCRIPTION_STATUS.CANCELLED && (
      <Button
        loading={loading}
        onClick={handleClick}
        type="text"
        className="text-btn"
      >
        Cancel
      </Button>
    )
  );
};

const columns = ({ refresh, isAddEditAllowed }) => [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    render: (_, { createdAt }) => moment(createdAt).format('D/M/YY')
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: (val) => `$${val?.toFixed(2)}`
  },
  {
    title: 'Frequency',
    dataIndex: 'type',
    render: (val) =>
      ({
        [DONATION_TYPES.ONE_TIME]: 'One Time',
        [DONATION_TYPES.MONTHLY]: 'Monthly',
        [DONATION_TYPES.ANNUALLY]: 'Annually'
      }[val] ?? '')
  },
  {
    title: 'Avatar',
    dataIndex: 'user',
    width: 100,
    render: (_, { user: { firstName, lastName, profileImage } }) => (
      <Avatar src={profileImage} firstName={firstName} lastName={lastName} />
    )
  },
  {
    title: 'F Name',
    dataIndex: 'user',
    ellipsis: true,
    render: (_, { user: { firstName } }) => firstName
  },
  {
    title: 'L Name',
    dataIndex: 'user',
    ellipsis: true,
    render: (_, { user: { lastName } }) => lastName
  },
  {
    title: 'Transaction ID',
    dataIndex: 'transactionId',
    render: (val) => `#${val}`
  },
  {
    title: 'Key',
    dataIndex: 'key'
  },
  {
    title: 'Payment Status',
    dataIndex: 'paymentStatus',
    render: (value) => (
      <Tag className="m-0 custom-tag" color={STATUS_COLORS[value]}>
        {value}
      </Tag>
    )
  },
  {
    title: 'Subscription Status',
    dataIndex: 'subscriptionStatus',
    render: (value) => (
      <Tag className="m-0 custom-tag" color={STATUS_COLORS[value]}>
        {value}
      </Tag>
    )
  },
  isAddEditAllowed && {
    title: ' ',
    dataIndex: 'id',
    render: (_, record) => <Action refresh={refresh} {...record} />
  }
];

const TYPE_OPTIONS = [
  { label: 'One Time', value: DONATION_TYPES.ONE_TIME },
  {
    label: 'Monthly',
    value: DONATION_TYPES.MONTHLY
  },
  {
    label: 'Annually',
    value: DONATION_TYPES.ANNUALLY
  }
];

const Donations = () => {
  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.USER_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const [type, setType] = useState(null);
  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: [
        { name: 'Created At', value: 'createdAt' },
        { name: 'Amount', value: 'amount' }
      ]
    }
  });
  const handleTypeChange = (val) => {
    setType(val);
  };

  return (
    <>
      <PageHeader />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Donations' }}
        show={{ listModes: false }}
      >
        <Select
          allowClear
          value={type}
          popupMatchSelectWidth={false}
          placeholder="Select type"
          options={TYPE_OPTIONS}
          onChange={handleTypeChange}
        />
      </FilterBar>
      <PageList
        filters={{ ...filterProps.filters, type }}
        listMode={LIST_TYPES.TABLE}
        query={GET_DONATIONS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{
          columns: (props) =>
            columns({ ...props, isAddEditAllowed })?.filter(
              (item) => item !== false
            )
        }}
        limit={20}
      />
    </>
  );
};

export default Donations;
