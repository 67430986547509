import { ConfigProvider, Typography } from 'antd';
import React from 'react';
import PageHeader from '../../components/PageHeader';

const { Title } = Typography;

const DashboardLanding = () => {
  return (
    <>
      <PageHeader />
      <div className="d-flex justify-center align-center h-full">
        <ConfigProvider
          theme={{ components: { Typography: { titleMarginBottom: 0 } } }}
        >
          <Title className="d-flex align-center">
            Welcome to{' '}
            <img
              className="dashboard-logo"
              src="/logo.png"
              alt="sidebar logo"
            />
          </Title>
        </ConfigProvider>
      </div>
    </>
  );
};

export default DashboardLanding;
