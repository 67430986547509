import { CheckOutlined } from '@ant-design/icons';
import { Button, Card, Space, theme } from 'antd';
import React, { useMemo, useState } from 'react';
import audioPlaceholder from '../../../../assets/images/audioPlaceholder.jpg';
import videoPlaceholder from '../../../../assets/images/imagePlaceholder.png';
import Live from '../../../../assets/images/live-new.png';
import { getImageUrl } from '../../../../common/utils';
import Image from '../../../../components/Image';
import SelectableModal from '../../../../components/SelectableModal';
import { GET_ARTICLES_MODULE } from '../../../articles/graphql/Queries';
import { GET_COMMUNITIES } from '../../../communities/graphql/Queries';
import { GET_PODCASTS_MODULE } from '../../../podcast/graphql/Queries';
import Preview from '../../../videos/components/Preview';
import { GET_VIDEOS_MODULES } from '../../../videos/graphql/Queries';

export const VideoCard = ({ onSelect, selected, data }) => {
  return (
    <Card
      className="selectable-modal-card cs-card"
      cover={
        <>
          {selected && (
            <span className="checked-icon">
              <CheckOutlined />
            </span>
          )}
          <Image
            className="label-poster"
            src={
              getImageUrl(data?.imageThumbnail?.url, {
                height: 200,
                width: 200
              }) ?? videoPlaceholder
            }
            alt=""
          />
        </>
      }
      onClick={() => onSelect(data)}
    >
      {data?.isLive && <img className="live-tag" src={Live} alt="Live" />}
      <Card.Meta title={<span className="label-title">{data?.title}</span>} />
    </Card>
  );
};

const CommunityCard = ({ onSelect, selected, data }) => {
  return (
    <Card
      className="selectable-modal-card cs-card"
      cover={
        <>
          {selected && (
            <span className="checked-icon">
              <CheckOutlined />
            </span>
          )}
          <Image
            className="label-poster"
            src={
              getImageUrl(data?.imageUrl, {
                height: 200,
                width: 200
              }) ?? videoPlaceholder
            }
            alt=""
          />
        </>
      }
      onClick={() => onSelect(data)}
    >
      <Card.Meta title={<span className="label-title">{data?.title}</span>} />
    </Card>
  );
};

export const SelectVideos = ({
  multiple = true,
  onChange,
  value,
  id: fieldId,
  isContentEditAllowed = true,
  disabled = false,
  IsTableView = false,
  isLive
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colorText } = theme.useToken().token;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value?.find((items) => items?.id === item?.id);
      if (isPresent) {
        onChange(value?.filter((items) => items?.id !== item?.id));
      } else {
        onChange([
          ...value,
          {
            id: item?.id,
            url: item?.imageThumbnail?.url ?? '',
            title: item?.title,
            isLive: item?.isLive,
            data: item
          }
        ]);
      }
      return;
    }
    onChange({
      id: item?.id,
      url: item?.imageThumbnail?.url ?? '',
      title: item?.title,
      isLive: item?.isLive,
      data: item
    });
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value?.filter((items) => items?.id !== item?.id));
    } else {
      onChange({
        id: '',
        url: '',
        title: '',
        data: null
      });
    }
  };

  const filters = useMemo(() => ({ isLive }), [isLive]);

  return (
    <>
      <SelectableModal
        title={multiple ? 'Select Videos' : 'Select Video'}
        open={isOpen}
        onClose={closeModal}
        query={GET_VIDEOS_MODULES}
        variablesSelector={(offset, limit, search, rest) => ({
          filter: { search, skip: offset, limit, ...rest }
        })}
        dataSelector={(data) => data?.videosAdmin?.videos ?? []}
        keys={{
          data: 'videosAdmin',
          records: 'videos',
          count: 'count'
        }}
        filters={filters}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value?.map((val) => val?.id).includes(item?.id)
            : value?.id === item?.id;
          return (
            <VideoCard
              data={item}
              selected={isSelected}
              onSelect={handleCardSelect}
            />
          );
        }}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value?.length > 0 &&
              value?.map((item) => (
                <Preview
                  onRemove={() => handleRemove(item)}
                  key={item?.id}
                  bgImg={
                    getImageUrl(item?.url, {
                      height: 200,
                      width: 200
                    }) || videoPlaceholder
                  }
                >
                  {item?.isLive && <Preview.Live />}
                  <Preview.Title color={colorText}>{item?.title}</Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                onRemove={handleRemove}
                key={value?.id}
                bgImg={
                  getImageUrl(value?.url, {
                    height: 200,
                    width: 200
                  }) || videoPlaceholder
                }
              >
                {value?.isLive && <Preview.Live />}
                <Preview.Title color={colorText}>{value.title}</Preview.Title>
              </Preview>
            )}
          </>
        )}

        <Button
          id={fieldId}
          htmlType="button"
          onClick={openModal}
          disabled={disabled || !isContentEditAllowed}
          className={IsTableView ? 'table-view-btn' : ''}
        >
          {value?.length > 0 ? 'Change' : 'Select'}{' '}
          {multiple ? 'Videos' : 'Video'}
        </Button>
      </Space>
    </>
  );
};

export const SelectCommunities = ({
  multiple = true,
  onChange,
  value,
  id: fieldId,
  isContentEditAllowed = true,
  disabled = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colorText } = theme.useToken().token;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value?.find((items) => items?.id === item?.id);
      if (isPresent) {
        onChange(value?.filter((items) => items?.id !== item?.id));
      } else {
        onChange([
          ...value,
          {
            id: item?.id,
            url: item?.imageUrl ?? '',
            title: item?.title
          }
        ]);
      }
      return;
    }
    onChange({
      id: item?.id,
      url: item?.imageUrl ?? '',
      title: item?.title
    });
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value?.filter((items) => items?.id !== item?.id));
    } else {
      onChange({
        id: '',
        url: '',
        title: ''
      });
    }
  };

  return (
    <>
      <SelectableModal
        title={multiple ? 'Select Communities' : 'Select Community'}
        open={isOpen}
        onClose={closeModal}
        query={GET_COMMUNITIES}
        variablesSelector={(offset, limit, search) => ({
          filter: { search, skip: offset, limit }
        })}
        dataSelector={(data) => data?.communitiesAdmin?.communities ?? []}
        keys={{
          data: 'communitiesAdmin',
          records: 'communities',
          count: 'count'
        }}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value?.map((val) => val?.id).includes(item?.id)
            : value?.id === item?.id;
          return (
            <CommunityCard
              data={item}
              selected={isSelected}
              onSelect={handleCardSelect}
            />
          );
        }}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value?.length > 0 &&
              value?.map((item) => (
                <Preview
                  onRemove={() => handleRemove(item)}
                  key={item?.id}
                  bgImg={
                    getImageUrl(item?.url, {
                      height: 200,
                      width: 200
                    }) || videoPlaceholder
                  }
                >
                  <Preview.Title color={colorText}>{item?.title}</Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                onRemove={handleRemove}
                key={value?.id}
                bgImg={
                  getImageUrl(value?.url, {
                    height: 200,
                    width: 200
                  }) || videoPlaceholder
                }
              >
                <Preview.Title color={colorText}>{value.title}</Preview.Title>
              </Preview>
            )}
          </>
        )}

        <Button
          id={fieldId}
          htmlType="button"
          onClick={openModal}
          disabled={disabled || !isContentEditAllowed}
        >
          {value?.length > 0 ? 'Change' : 'Select'}{' '}
          {multiple ? 'Communities' : 'Community'}
        </Button>
      </Space>
    </>
  );
};

const PodcastCard = ({ onSelect, selected, data }) => {
  return (
    <Card
      className="selectable-modal-card cs-card"
      cover={
        <>
          {selected && (
            <span className="checked-icon">
              <CheckOutlined />
            </span>
          )}
          <Image
            className="label-poster"
            src={
              getImageUrl(data?.imageThumbnail?.url, {
                height: 200,
                width: 200
              }) ?? audioPlaceholder
            }
            alt=""
          />
        </>
      }
      onClick={() => onSelect(data)}
    >
      <Card.Meta title={<span className="label-title">{data?.title}</span>} />
    </Card>
  );
};

const ArticleCard = ({ onSelect, selected, data }) => {
  return (
    <Card
      className="selectable-modal-card cs-card"
      cover={
        <>
          {selected && (
            <span className="checked-icon">
              <CheckOutlined />
            </span>
          )}
          <Image
            className="label-poster"
            src={
              getImageUrl(data?.imageThumbnail?.url, {
                height: 200,
                width: 200
              }) ?? videoPlaceholder
            }
            alt=""
          />
        </>
      }
      onClick={() => onSelect(data)}
    >
      <Card.Meta title={<span className="label-title">{data?.title}</span>} />
    </Card>
  );
};

export const SelectArticle = ({
  multiple = true,
  onChange,
  value,
  id: fieldId,
  isContentEditAllowed = true,
  disabled = false,
  IsTableView = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colorText } = theme.useToken().token;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value?.find((items) => items?.id === item?.id);
      if (isPresent) {
        onChange(value?.filter((items) => items?.id !== item?.id));
      } else {
        onChange([
          ...value,
          {
            id: item?.id,
            url: item?.imageThumbnail?.url ?? '',
            title: item?.title,
            data: item
          }
        ]);
      }
      return;
    }
    onChange({
      id: item?.id,
      url: item?.imageThumbnail?.url ?? '',
      title: item?.title,
      data: item
    });
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value?.filter((items) => items?.id !== item?.id));
    } else {
      onChange({
        id: '',
        url: '',
        title: '',
        data: null
      });
    }
  };

  return (
    <>
      <SelectableModal
        title={multiple ? 'Select Articles' : 'Select Article'}
        open={isOpen}
        onClose={closeModal}
        query={GET_ARTICLES_MODULE}
        variablesSelector={(offset, limit, search) => ({
          filter: { search, skip: offset, limit }
        })}
        dataSelector={(data) => data?.articlesAdmin?.articles ?? []}
        keys={{
          data: 'articlesAdmin',
          records: 'articles',
          count: 'count'
        }}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value?.map((val) => val?.id)?.includes(item?.id)
            : value?.id === item?.id;
          return (
            <ArticleCard
              data={item}
              selected={isSelected}
              onSelect={handleCardSelect}
            />
          );
        }}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value?.length > 0 &&
              value?.map((item) => (
                <Preview
                  onRemove={() => handleRemove(item)}
                  key={item?.id}
                  bgImg={
                    getImageUrl(item?.url, {
                      height: 200,
                      width: 200
                    }) || videoPlaceholder
                  }
                >
                  <Preview.Title color={colorText}>{item?.title}</Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                onRemove={handleRemove}
                key={value?.id}
                bgImg={
                  getImageUrl(value?.url, {
                    height: 200,
                    width: 200
                  }) || videoPlaceholder
                }
              >
                <Preview.Title color={colorText}>{value?.title}</Preview.Title>
              </Preview>
            )}
          </>
        )}

        <Button
          id={fieldId}
          htmlType="button"
          onClick={openModal}
          disabled={disabled || !isContentEditAllowed}
          className={IsTableView ? 'table-view-btn' : ''}
        >
          {value?.length > 0 ? 'Change' : 'Select'}{' '}
          {multiple ? 'Articles' : 'Article'}
        </Button>
      </Space>
    </>
  );
};

export const SelectPodcasts = ({
  multiple = true,
  onChange,
  value,
  id: fieldId,
  isContentEditAllowed = true,
  disabled = false,
  IsTableView = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colorText } = theme.useToken().token;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value?.find((items) => items?.id === item?.id);
      if (isPresent) {
        onChange(value?.filter((items) => items?.id !== item?.id));
      } else {
        onChange([
          ...value,
          {
            id: item?.id,
            url: item?.imageThumbnail?.url ?? '',
            title: item?.title,
            data: item
          }
        ]);
      }
      return;
    }
    onChange({
      id: item?.id,
      url: item?.imageThumbnail?.url ?? '',
      title: item?.title,
      data: item
    });
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value?.filter((items) => items?.id !== item?.id));
    } else {
      onChange({
        id: '',
        url: '',
        title: '',
        data: null
      });
    }
  };

  return (
    <>
      <SelectableModal
        title={multiple ? 'Select Podcasts' : 'Select Podcast'}
        open={isOpen}
        onClose={closeModal}
        query={GET_PODCASTS_MODULE}
        variablesSelector={(offset, limit, search) => ({
          filter: { search, skip: offset, limit }
        })}
        dataSelector={(data) => data?.podcastsAdmin?.podcasts ?? []}
        keys={{
          data: 'podcastsAdmin',
          records: 'podcasts',
          count: 'count'
        }}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value?.map((val) => val?.id).includes(item?.id)
            : value?.id === item?.id;
          return (
            <PodcastCard
              data={item}
              selected={isSelected}
              onSelect={handleCardSelect}
            />
          );
        }}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value?.length > 0 &&
              value?.map((item) => (
                <Preview
                  onRemove={() => handleRemove(item)}
                  key={item?.id}
                  bgImg={
                    getImageUrl(item?.url, {
                      height: 200,
                      width: 200
                    }) || audioPlaceholder
                  }
                >
                  <Preview.Title color={colorText}>{item?.title}</Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                onRemove={handleRemove}
                key={value?.id}
                bgImg={
                  getImageUrl(value?.url, {
                    height: 200,
                    width: 200
                  }) || audioPlaceholder
                }
              >
                <Preview.Title color={colorText}>{value.title}</Preview.Title>
              </Preview>
            )}
          </>
        )}

        <Button
          id={fieldId}
          htmlType="button"
          onClick={openModal}
          disabled={disabled || !isContentEditAllowed}
          className={IsTableView ? 'table-view-btn' : ''}
        >
          {value?.id ? 'Change' : 'Select'} {multiple ? 'Podcasts' : 'Podcast'}
        </Button>
      </Space>
    </>
  );
};
