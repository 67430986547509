import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import AddEditForm from './AddEditForm';
import FormList from './FormList';

const FormWrapper = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.FORMS}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <FormList {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES.FORMS}/add`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditForm {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES.FORMS}/:formId/edit`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditForm {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES.FORMS}/:formId`}
        render={() => <Redirect to={ROUTES.FORMS} />}
      />
    </Switch>
  );
};

export default FormWrapper;
