import { Flex, Spin } from 'antd';
import { FileArrowDown } from 'phosphor-react';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import {
  ASSET_CATEGORY,
  AUDIO_SERVICE_TYPES
} from '../../../../common/constants';
import { getFilenameFormUrl } from '../../../../common/utils';
import Image from '../../../../components/Image';

export default function Attachment({
  blurhash,
  serviceImageThumbnail,
  serviceVideoThumbnail,
  url,
  categoryKey,
  service,
  title
}) {
  const [loading, setLoading] = useState(false);
  if ([ASSET_CATEGORY.IMAGE, ASSET_CATEGORY.ICON].includes(categoryKey)) {
    return (
      <div className="image-wrapper">
        <Image src={url} blurHash={blurhash} alt={title} />
      </div>
    );
  }
  if (
    categoryKey === ASSET_CATEGORY.VIDEO ||
    (categoryKey === ASSET_CATEGORY.AUDIO &&
      service === AUDIO_SERVICE_TYPES.PEERTUBE)
  ) {
    return (
      <ReactPlayer
        controls
        height="100%"
        width="100%"
        url={url}
        wrapper={(props) => <div className="video-player-wrapper" {...props} />}
        light={
          <Image
            src={serviceVideoThumbnail || serviceImageThumbnail}
            blurHash={blurhash}
            alt={title}
          />
        }
      />
    );
  }
  if (categoryKey === ASSET_CATEGORY.AUDIO) {
    return (
      <ReactPlayer
        controls
        width="100%"
        wrapper={(props) => <div className="audio-player-wrapper" {...props} />}
        url={url}
      />
    );
  }

  const handleFileDownload = async () => {
    if (!url) return;

    setLoading(true);
    try {
      const data = await fetch(url);
      const blob = await data.blob();
      const objectUrl = URL.createObjectURL(blob);
      const filename = getFilenameFormUrl(url);
      // eslint-disable-next-line no-undef
      const link = document.createElement('a');

      link.setAttribute('href', objectUrl);
      link.setAttribute('download', filename);
      link.style.display = 'none';

      // eslint-disable-next-line no-undef
      document.body.appendChild(link);

      link.click();

      // eslint-disable-next-line no-undef
      document.body.removeChild(link);
    } catch (err) {
      // do nothing
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      onClick={handleFileDownload}
      className="mb-8 pointer"
      align="center"
      gap={8}
    >
      {loading ? <Spin size="large" /> : <FileArrowDown size={32} />}
      <span>{title}</span>
    </Flex>
  );
}
