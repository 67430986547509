import { gql } from '@apollo/client';

export const CREATE_COMMUNITY = gql`
  mutation createCommunityAdmin($data: CreateCommunityAdminInput!) {
    createCommunityAdmin(data: $data) {
      message
    }
  }
`;

export const UPDATE_COMMUNITY = gql`
  mutation updateCommunityAdmin(
    $where: CommunityWhereUniqueInput!
    $data: UpdateCommunityAdminInput!
  ) {
    updateCommunityAdmin(where: $where, data: $data) {
      message
    }
  }
`;

export const INVITE_MEMBERS = gql`
  mutation inviteMembersInCommunityAdmin(
    $where: CommunityWhereUniqueInput!
    $data: InviteMembersInCommunityInput!
  ) {
    inviteMembersInCommunityAdmin(where: $where, data: $data) {
      message
    }
  }
`;

export const REMOVE_COMMUNITY_MEMBER = gql`
  mutation RemoveCommunityMemberAdmin(
    $where: RemoveCommunityMemberWhereUniqueInput!
  ) {
    removeCommunityMemberAdmin(where: $where) {
      message
    }
  }
`;

export const UPDATE_COMMUNITY_MEMBER = gql`
  mutation UpdateCommunityMemberAdmin(
    $where: CommunityMemberWhereUniqueInput!
    $data: UpdateCommunityMemberData!
  ) {
    updateCommunityMemberAdmin(where: $where, data: $data) {
      message
    }
  }
`;

export const TRANSFER_OWNERSHIP = gql`
  mutation TransferCommunityOwnershipAdmin($where: TransferCommunityInput!) {
    transferCommunityOwnershipAdmin(where: $where) {
      message
    }
  }
`;
