import { useMutation } from '@apollo/client';
import { Button, Result } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { authClient } from '../../apollo';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import { VERIFY_USER_ADMIN } from './graphql/Mutations';

const VerifyUser = () => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const token = params?.get('token');
  const uid = params?.get('uid');
  const setPassword = params?.get('setPassword');
  const [loading, setLoading] = useState(true);
  const [showErrorState, setShowErrorState] = useState(false);

  const [verifyUser] = useMutation(VERIFY_USER_ADMIN, {
    client: authClient,
    onCompleted(res) {
      if (res?.verifyUserAdmin) {
        if (setPassword?.toLowerCase() === 'true') {
          history?.push({
            pathname: ROUTES?.SET_PASSWORD,
            state: { uid, token: res?.verifyUserAdmin?.resetToken }
          });
        } else {
          history?.replace(ROUTES?.LOGIN);
        }
        setLoading(false);
      }
      setShowErrorState(false);
    },
    onError() {
      setLoading(false);
      setShowErrorState(true);
    }
  });

  useEffect(() => {
    if (uid && token) {
      setLoading(true);
      verifyUser({
        variables: {
          data: {
            uid,
            token,
            setPassword: setPassword?.toLowerCase() === 'true'
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, token]);

  return (
    <div className="d-flex justify-center align-center flex-vertical">
      <LoaderComponent setHeight={80} spinning={loading} size="large" />
      {showErrorState && (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Button
              type="primary"
              block
              className="cs-button bg-n-900 text-n-100 hbg-black-80 hc-n-100 hb-black-90"
              onClick={() => history?.replace(ROUTES?.MAIN)}
            >
              Back Home
            </Button>
          }
        />
      )}
    </div>
  );
};

export default VerifyUser;
