import { DeleteOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Modal, Space, Tag, Tooltip } from 'antd';
import { isFunction } from 'lodash';
import { CheckCircle, Minus, UserSwitch, XCircle } from 'phosphor-react';
import React, { useMemo, useRef, useState } from 'react';
import { SelectUsers } from '.';
import {
  LIST_TYPES,
  ROLES,
  ROLE_KEYS,
  STATUS_COLORS
} from '../../../common/constants';
import { modalContext } from '../../../components/AppComponentContainer';
import Avatar from '../../../components/Avatar';
import PageList from '../../../components/PageList';
import {
  INVITE_MEMBERS,
  REMOVE_COMMUNITY_MEMBER,
  TRANSFER_OWNERSHIP,
  UPDATE_COMMUNITY_MEMBER
} from '../graphql/Mutations';
import { GET_COMMUNITY_MEMBERS } from '../graphql/Queries';

const status = {
  ACTIVE: 'Active',
  REQUESTED: 'Requested',
  REJECTED: 'Rejected'
};

const variablesSelector = ({
  limit,
  offset,
  sortField,
  sortOrder,
  communityId
}) => ({
  filter: {
    skip: offset,
    limit,
    communityId
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ communityMembersAdmin }) => ({
  data: communityMembersAdmin?.communityMembers ?? [],
  count: communityMembersAdmin?.count ?? 0
});

const initialValues = {
  members: []
};

function InviterMembersForm({ communityId, onSuccess, onClose }) {
  const [form] = Form.useForm();
  const [inviteMembers, { loading }] = useMutation(INVITE_MEMBERS);

  const handleSubmit = ({ members }) => {
    const payload = {
      where: {
        id: communityId
      },
      data: {
        emails: members?.map(({ value }) => value) ?? []
      }
    };
    inviteMembers({
      variables: payload
    })
      .then(() => {
        form?.resetFields();
        if (isFunction(onSuccess)) onSuccess(members?.length);
      })
      .catch();
  };

  const handleCancel = () => {
    if (isFunction(onClose)) onClose();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={handleSubmit}
    >
      <Form.Item label="Members" name="members">
        <SelectUsers placeholder="Select Members" />
      </Form.Item>

      <div className="d-flex justify-end button-section mb-8">
        <Space>
          <Button
            disabled={loading}
            type="text"
            className="text-btn2"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            loading={loading}
            type="text"
            htmlType="submit"
            className="text-btn mr-8"
            size="middle"
          >
            Save
          </Button>
        </Space>
      </div>
    </Form>
  );
}

function InviteMembersModal({ open, onClose, communityId, onSuccess }) {
  return (
    <Modal
      footer={null}
      title="Invite Members"
      open={open}
      onCancel={onClose}
      width={800}
      destroyOnClose
      centered
    >
      <InviterMembersForm
        communityId={communityId}
        onClose={onClose}
        onSuccess={onSuccess}
      />
    </Modal>
  );
}

const ManageMembersModal = ({
  open,
  setOpen,
  communityId,
  isAddEditAllowed,
  updateData
}) => {
  const listingRef = useRef(null);
  const [openInviteModal, setOpenInviteModal] = useState(false);

  const [removeCommunityMember] = useMutation(REMOVE_COMMUNITY_MEMBER, {
    onError() {}
  });

  const [updateCommunityMember] = useMutation(UPDATE_COMMUNITY_MEMBER, {
    onError() {}
  });

  const [transferOwnership] = useMutation(TRANSFER_OWNERSHIP, {
    onError() {}
  });

  const handleRemove = (id, removeItem, updateCommunityData) => {
    modalContext?.confirm({
      title: 'Are you sure, you want to remove this member?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      onOk: async () => {
        return removeCommunityMember({
          variables: { where: { communityId, communityMemberId: id } }
        })
          .then(({ errors }) => {
            if (removeItem && !errors) {
              removeItem(id);
              updateCommunityData(communityId, (record) => ({
                ...record,
                membersCount: record?.membersCount - 1
              }));
            }
          })
          .catch((err) => err);
      }
    });
  };

  const handleAcceptReject = (isReject, id, refetch, updateCommunityData) => {
    modalContext?.confirm({
      title: `Are you sure, you want to ${isReject ? 'reject' : 'accept'}?`,
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      onOk: async () => {
        return updateCommunityMember({
          variables: {
            where: { id },
            data: { status: isReject ? 'REJECT' : 'ACCEPT' }
          }
        })
          .then(({ errors }) => {
            if (refetch && !errors) {
              refetch();
              if (!isReject && updateCommunityData) {
                updateCommunityData(communityId, (record) => ({
                  ...record,
                  membersCount: record?.membersCount + 1
                }));
              }
            }
          })
          .catch((err) => err);
      }
    });
  };

  const handleTransferOwnership = (id, refetch) => {
    modalContext?.confirm({
      title: 'Are you sure, you want to transfer the ownership?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      onOk: async () => {
        return transferOwnership({
          variables: { where: { communityMemberId: id } }
        })
          .then(({ errors }) => {
            if (refetch && !errors) {
              refetch();
            }
          })
          .catch((err) => err);
      }
    });
  };

  const columns = useMemo(
    () => ({ removeItem, updateCommunityData, refresh }) => [
      {
        title: 'Avatar',
        dataIndex: 'profileImage',
        key: 'profileImage',
        width: 100,
        render: (_, { member: { firstName, lastName, profileImage } }) => {
          return (
            <Avatar
              src={profileImage}
              firstName={firstName}
              lastName={lastName}
            />
          );
        }
      },
      {
        title: 'Name',
        key: 'name',
        render: (_, record) => {
          return `${record?.member?.firstName} ${record?.member?.lastName}`;
        },
        width: 200
      },
      {
        title: 'Email',
        dataIndex: ['member', 'email'],
        width: 250,
        render: (value) => <a href={`mailto:${value}`}>{value}</a>
      },
      {
        title: 'Role',
        dataIndex: 'role',
        width: 100,
        render: (value) => <p>{ROLES[value]}</p>
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '22%',
        render: (value) => (
          <Tag className="m-0 custom-tag" color={STATUS_COLORS?.[value]}>
            {status?.[value]}
          </Tag>
        )
      },
      isAddEditAllowed
        ? {
            title: 'Action',
            dataIndex: 'id',
            render: (_, record) => (
              <>
                {record?.status === 'REQUESTED' ? (
                  <div className="d-flex">
                    <Tooltip title="Accept">
                      <Button
                        type="text"
                        className="text-btn"
                        onClick={() =>
                          handleAcceptReject(
                            false,
                            record?.id,
                            refresh,
                            updateCommunityData
                          )
                        }
                      >
                        <CheckCircle width={30} height={30} color="#23af33" />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Reject">
                      <Button
                        type="text"
                        className="text-btn"
                        onClick={() =>
                          handleAcceptReject(
                            true,
                            record?.id,
                            refresh,
                            updateCommunityData
                          )
                        }
                      >
                        <XCircle width={30} height={30} color="#FF4D4F" />
                      </Button>
                    </Tooltip>
                  </div>
                ) : (
                  <div className="d-flex">
                    {record?.role !== ROLE_KEYS.OWNER ? (
                      <>
                        <Tooltip title="Transfer Ownership">
                          <Button
                            type="text"
                            className="text-btn"
                            onClick={() =>
                              handleTransferOwnership(record?.id, refresh)
                            }
                          >
                            <UserSwitch size={24} />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Remove Member">
                          <Button
                            type="text"
                            className="text-btn"
                            onClick={() =>
                              handleRemove(
                                record?.id,
                                removeItem,
                                updateCommunityData
                              )
                            }
                          >
                            <DeleteOutlined />
                          </Button>
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        <Minus size={15} />
                      </>
                    )}
                  </div>
                )}
              </>
            ),
            width: 150
          }
        : []
    ],
    [isAddEditAllowed]
  );

  const filters = useMemo(() => ({ communityId }), [communityId]);

  const handleModalClose = () => setOpenInviteModal(false);

  const handleInvite = () => setOpenInviteModal(true);

  const handleSuccess = (newMembers = 0) => {
    listingRef?.current?.refresh();
    updateData(communityId, (record) => ({
      ...record,
      membersCount: record?.membersCount + newMembers
    }));
    setOpenInviteModal(false);
  };

  return (
    <Modal
      footer={null}
      title={
        <div className="d-flex justify-between align-center">
          <p>Manage Members</p>
          {isAddEditAllowed && (
            <Button
              type="primary"
              onClick={handleInvite}
              icon={<UsergroupAddOutlined />}
            >
              Invite Members
            </Button>
          )}
        </div>
      }
      open={open}
      onCancel={() => {
        setOpen(false);
      }}
      width={1080}
      destroyOnClose
      centered
    >
      <InviteMembersModal
        open={openInviteModal}
        onClose={handleModalClose}
        communityId={communityId}
        onSuccess={handleSuccess}
      />
      <PageList
        ref={listingRef}
        filters={filters}
        listMode={LIST_TYPES.TABLE}
        query={GET_COMMUNITY_MEMBERS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{
          columns: (props) =>
            columns({ ...props, updateCommunityData: updateData }),
          className: 'manage-member-table'
        }}
      />
    </Modal>
  );
};

export default ManageMembersModal;
