import { Flex } from 'antd';

import React, { useMemo, useState } from 'react';
import {
  LIST_TYPES,
  MODULES,
  POSTS_SORT_FIELD
} from '../../../common/constants';
import FilterBar, { useFilterBar } from '../../../components/FilterBar';
import PageHeader from '../../../components/PageHeader';
import PageList from '../../../components/PageList';
import { GET_COMMUNITIES } from '../../communities/graphql/Queries';
import { CheckBox } from '../../pages/component/pageModules/moduleForms/FormInputs';
import { Select } from '../../videos/components/FormInputs';
import PostCard from './components/PostCard';
import { GET_POSTS } from './graphql/Queries';

const dataSelector = (data) => ({
  data: data?.postsAdmin?.posts ?? [],
  count: data?.postsAdmin?.count ?? 0
});

const variablesSelector = ({
  offset,
  sortField,
  sortOrder,
  search: _search,
  ...rest
}) => ({
  filter: {
    skip: offset,
    ...rest
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const Posts = () => {
  const [community, setCommunity] = useState(null);
  const [visibility, setVisibility] = useState(false);

  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: POSTS_SORT_FIELD,
      field: POSTS_SORT_FIELD[0].value
    }
  });

  const handleCommunityChange = (_, value) => {
    setCommunity(value);
  };

  const filters = useMemo(
    () => ({
      ...filterProps.filters,
      communityId: community?.value,
      isDeleted: visibility
    }),
    [filterProps.filters, community, visibility]
  );

  const handleVisibilityChange = (value) => {
    const {
      target: { checked: checkedValue }
    } = value;
    setVisibility(checkedValue);
  };

  return (
    <>
      <PageHeader menu={MODULES?.MODERATION} />
      <FilterBar
        {...filterProps}
        show={{
          listModes: false,
          search: false
        }}
      >
        <Select
          popupClassName="community-select"
          allowClear
          popupMatchSelectWidth={false}
          value={community}
          onChange={handleCommunityChange}
          placeholder="Select Community"
          query={GET_COMMUNITIES}
          variablesSelector={(filter) => ({ filter })}
          dataSelector={(data) =>
            data?.communitiesAdmin?.communities?.map(({ id, title }) => ({
              label: title,
              value: id
            })) ?? []
          }
          keys={{
            data: 'communitiesAdmin',
            records: 'communities',
            count: 'count'
          }}
        />
        <CheckBox
          checked={visibility}
          onChange={handleVisibilityChange}
          label={
            <Flex align="center" gap={4}>
              Hidden
            </Flex>
          }
        />
      </FilterBar>
      <div className="moderation-posts">
        <PageList
          filters={filters}
          listMode={LIST_TYPES.GRID}
          query={GET_POSTS}
          variablesSelector={variablesSelector}
          dataSelector={dataSelector}
          GridProps={{
            renderItem: (record, { removeItem }) => (
              <PostCard {...record} removeItem={removeItem} />
            ),
            breakPoints: { md: 24, lg: 12 }
          }}
        />
      </div>
    </>
  );
};

export default Posts;
