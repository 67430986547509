import { EditOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tooltip
} from 'antd';
import {
  differenceBy,
  flattenDeep,
  groupBy,
  hasIn,
  isEmpty,
  map,
  omit
} from 'lodash';
import { CurrencyDollar } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import {
  MAX_LENGTHS,
  PLAN_FREQUENCY_OPTIONS,
  PLAN_TYPE_KEYS,
  PLAN_TYPE_OPTIONS,
  REGEX,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import {
  formValidatorRules,
  normalizeNegativeNumber
} from '../../common/utils';
import LoadEditor from '../../components/LoadEditor';
import PageHeader from '../../components/PageHeader';
import useCheckPermission from '../../hooks/useCheckPermission';
import { Switch } from '../pages/component/pageModules/moduleForms/FormInputs';
import PermissionsModal from './components/PermissionsModal';
import { CREATE_PLAN, UPDATE_PLAN } from './graphql/Mutations';
import {
  GET_SUBSCRIPTION_PLAN,
  SUBSCRIPTION_PERMISSIONS
} from './graphql/Queries';

let sameKey = 0;

const AddEditPlans = ({ history, match: { params } }) => {
  const { planId } = params;
  const isEdit = !!planId;
  const [permissions, setPermissions] = useState([]);
  const [record, setRecord] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [permissionsValues, setPermissionsValues] = useState({});
  const [form] = Form.useForm();
  const applyPermissions = Form.useWatch('applyPermissions', form);
  const type = Form.useWatch('type', form);
  const planKey = Form.useWatch('key', form);

  const isFreeOrGuest = ['FREE', 'GUEST'].includes(planKey);

  const [getSubscriptionPlan, { loading: fetchingDetails }] = useLazyQuery(
    GET_SUBSCRIPTION_PLAN,
    {
      onCompleted(res) {
        if (res?.subscriptionPlan) {
          form?.setFieldsValue({
            ...res?.subscriptionPlan,
            amount: res?.subscriptionPlan?.amount?.usd?.price
          });
          let updateValues = {};
          const permissionTypes = permissions.reduce(
            (obj, cur) => ({ ...obj, [cur.key]: cur.type }),
            {}
          );
          const difference = differenceBy(
            permissions,
            res?.subscriptionPlan?.permissions,
            'key'
          );
          map(
            [...res?.subscriptionPlan?.permissions, ...difference],
            (item) => {
              if (hasIn(item, 'allowed')) {
                updateValues = {
                  ...updateValues,
                  [item?.key]: omit(
                    {
                      ...item,
                      errorMessage: isEmpty(item?.errorMessage)
                        ? null
                        : {
                            label: item?.errorMessage,
                            value: item?.errorMessage
                          },
                      limitMessage: isEmpty(item?.limitMessage)
                        ? null
                        : {
                            label: item?.limitMessage,
                            value: item?.limitMessage
                          },
                      errorTitle: isEmpty(item?.errorTitle)
                        ? null
                        : {
                            label: item?.errorTitle,
                            value: item?.errorTitle
                          },
                      limitTitle: isEmpty(item?.limitTitle)
                        ? null
                        : {
                            label: item?.limitTitle,
                            value: item?.limitTitle
                          },
                      permissionType: permissionTypes?.[item?.key]
                    },
                    ['key', '__typename']
                  )
                };
              } else {
                updateValues = {
                  ...updateValues,
                  [item?.key]: {
                    allowed: true,
                    applyLimit: false,
                    limit: 0,
                    frequency: 0,
                    errorMessage: null,
                    limitMessage: null,
                    errorTitle: null,
                    limitTitle: null
                  }
                };
              }
            }
          );
          setPermissionsValues(updateValues);
        }
      },
      fetchPolicy: 'network-only',
      onError() {}
    }
  );

  const [fetchSubscriptionPermissions, { loading }] = useLazyQuery(
    SUBSCRIPTION_PERMISSIONS,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        const groupPermissions = groupBy(
          res?.subscriptionPlanPermissions,
          'group'
        );
        const updatedPermissions = Object.values(groupPermissions);
        setPermissions(flattenDeep(updatedPermissions));
        if (!isEdit) {
          let updateValues = {};
          map(res?.subscriptionPlanPermissions, (item) => {
            updateValues = {
              ...updateValues,
              [item?.key]: {
                allowed: true,
                applyLimit: false,
                limit: 0,
                frequency: 0,
                errorMessage: null,
                limitMessage: null,
                permissionType: item?.type,
                errorTitle: null,
                limitTitle: null
              }
            };
          });
          setPermissionsValues(updateValues);
        }
        if (planId) {
          getSubscriptionPlan({
            variables: {
              where: {
                id: planId
              }
            }
          });
        }
      },
      onError() {}
    }
  );

  const [
    createUpdateSubscriptionPlan,
    { loading: createLoading }
  ] = useMutation(isEdit ? UPDATE_PLAN : CREATE_PLAN, {
    onError() {}
  });

  useEffect(() => {
    fetchSubscriptionPermissions();
  }, []);

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.SUBSCRIPTION_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.SUBSCRIPTION_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const normalizeAmount = (value) => {
    if (!value) {
      return null;
    }
    let newValue = value;
    newValue =
      newValue.indexOf('.') >= 0
        ? newValue.substr(0, newValue.indexOf('.')) +
          newValue.substr(newValue.indexOf('.'), 3)
        : newValue;

    return +newValue < 0 ? 0 : newValue;
  };

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      order: Number(values?.order),
      amount: {
        usd: {
          price: Number(values?.amount)
        }
      },
      ...(values?.applyPermissions && {
        permissions: map(permissionsValues, (item, key) =>
          omit(
            {
              key,
              ...item,
              frequency: Number(item?.frequency),
              limit: Number(item?.limit),
              errorMessage: item?.errorMessage?.value ?? '',
              limitMessage: item?.limitMessage?.value ?? '',
              errorTitle: item?.errorTitle?.value ?? '',
              limitTitle: item?.limitTitle?.value ?? ''
            },
            'permissionType'
          )
        )
      })
    };
    if (isEdit) {
      delete payload.key;
      delete payload.frequency;
      delete payload.type;
    }
    const res = await createUpdateSubscriptionPlan({
      variables: {
        data: payload,
        ...(isEdit && {
          where: {
            id: planId
          }
        })
      }
    });
    if (res?.data) {
      history?.push(ROUTES.SUBSCRIPTION_PLANS);
    }
  };

  const columns = [
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
      fixed: 'left',
      onCell: (recordValue) => {
        const count = permissions?.filter(
          (item) => item?.group === recordValue?.group
        )?.length;
        if (!(sameKey !== recordValue?.group)) {
          return { rowSpan: 0 };
        }
        sameKey = recordValue?.group;
        return { rowSpan: count };
      },
      render: (value) => {
        return value?.split('_')?.join(' ');
      }
    },
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
      render: (key) => key?.split('_')?.join(' ')
    },
    {
      title: 'Permissions',
      key: 'permissions',
      render: (value, recordValue) => {
        return (
          <div className="d-flex align-center">
            {permissionsValues?.[recordValue?.key] && (
              <div className="view-plan-permissions">
                <p>
                  <strong>Allowed:</strong>{' '}
                  <span>
                    {permissionsValues?.[recordValue?.key]?.allowed?.toString()}
                  </span>
                </p>
                <p>
                  <strong>Error Title:</strong>{' '}
                  <span
                    title={
                      permissionsValues?.[recordValue?.key]?.errorTitle?.label
                    }
                  >
                    {!isEmpty(permissionsValues?.[recordValue?.key]?.errorTitle)
                      ? permissionsValues?.[recordValue?.key]?.errorTitle?.label
                      : '-'}
                  </span>
                </p>
                <p>
                  <strong>Error Message:</strong>{' '}
                  <span
                    title={
                      permissionsValues?.[recordValue?.key]?.errorMessage?.label
                    }
                  >
                    {!isEmpty(
                      permissionsValues?.[recordValue?.key]?.errorMessage
                    )
                      ? permissionsValues?.[recordValue?.key]?.errorMessage
                          ?.label
                      : '-'}
                  </span>
                </p>
                <p>
                  <strong>Apply Limit:</strong>{' '}
                  <span>
                    {permissionsValues?.[
                      recordValue?.key
                    ]?.applyLimit?.toString()}
                  </span>
                </p>
                <p>
                  <strong>Frequency:</strong>{' '}
                  <span>
                    {permissionsValues?.[recordValue?.key]?.frequency ?? 0}
                  </span>
                </p>
                <p>
                  <strong>Limit:</strong>{' '}
                  <span>
                    {permissionsValues?.[recordValue?.key]?.limit ?? 0}
                  </span>
                </p>
                <p>
                  <strong>Limit Title:</strong>{' '}
                  <span
                    title={
                      permissionsValues?.[recordValue?.key]?.limitTitle?.label
                    }
                  >
                    {!isEmpty(permissionsValues?.[recordValue?.key]?.limitTitle)
                      ? permissionsValues?.[recordValue?.key]?.limitTitle?.label
                      : '-'}
                  </span>
                </p>
                <p>
                  <strong>Limit Message:</strong>{' '}
                  <span
                    title={
                      permissionsValues?.[recordValue?.key]?.limitMessage?.label
                    }
                  >
                    {!isEmpty(
                      permissionsValues?.[recordValue?.key]?.limitMessage
                    )
                      ? permissionsValues?.[recordValue?.key]?.limitMessage
                          ?.label
                      : '-'}
                  </span>
                </p>
              </div>
            )}
          </div>
        );
      }
    },
    {
      title: 'ACTIONS',
      key: 'actions',
      fixed: 'right',
      render: (recordValue) => {
        return (
          <Tooltip
            title={`${
              permissionsValues?.[recordValue?.key] ? 'Update' : 'Add'
            } permissions`}
          >
            <Button
              type="text"
              className="text-btn"
              onClick={() => {
                setRecord(recordValue);
                setOpenModal(true);
              }}
              disabled={isEdit && isFreeOrGuest}
            >
              <EditOutlined />
            </Button>
          </Tooltip>
        );
      }
    }
  ];

  const handleCancel = () => {
    history.push(ROUTES.SUBSCRIPTION_PLANS);
  };

  return (
    <>
      {openModal && (
        <PermissionsModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          record={record}
          setPermissionsValues={setPermissionsValues}
          permissionsValues={permissionsValues}
        />
      )}
      <PageHeader />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form plan-form"
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{
              isActive: false,
              showOnPaywall: false,
              applyPermissions: false
            }}
            disabled={isViewOnly || fetchingDetails}
          >
            <Row gutter={[16, 0]}>
              <Col md={24} lg={12}>
                <Form.Item
                  label="Key"
                  name="key"
                  required
                  rules={[
                    {
                      validator(rule, value) {
                        if (!value) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject('Please enter a key!');
                        }
                        if (!REGEX?.KEY_VALUE?.test(value)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject('Should be a valid key!');
                        }
                        return Promise?.resolve();
                      }
                    },
                    // need to set 100 as per requirement
                    formValidatorRules?.maxLength(100)
                  ]}
                >
                  <Input placeholder="Enter key" disabled={isEdit} />
                </Form.Item>
                <Form.Item
                  label="Name"
                  name="name"
                  required
                  rules={[
                    formValidatorRules?.required('Please enter name!'),
                    formValidatorRules?.maxLength(MAX_LENGTHS.NAME)
                  ]}
                >
                  <Input placeholder="Enter name" />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea placeholder="Enter description" />
                </Form.Item>
                <Form.Item
                  name="amount"
                  normalize={normalizeAmount}
                  label="Amount"
                  rules={[{ required: true, message: 'Please enter amount!' }]}
                >
                  <Input
                    prefix={<CurrencyDollar size={20} />}
                    type="number"
                    placeholder="Enter Amount"
                    className="amount-input"
                  />
                </Form.Item>
                <Form.Item
                  name="order"
                  label="Order"
                  normalize={normalizeNegativeNumber}
                >
                  <Input
                    type="number"
                    placeholder="Enter Order"
                    className="number-input"
                  />
                </Form.Item>
                <Form.Item
                  label="Frequency"
                  name="frequency"
                  rules={[
                    !isEdit && {
                      required: true,
                      message: 'Please select frequency!'
                    }
                  ]}
                >
                  <Select
                    placeholder="Select frequency"
                    disabled={isEdit}
                    options={PLAN_FREQUENCY_OPTIONS}
                  />
                </Form.Item>
                <Form.Item
                  label="Type"
                  name="type"
                  rules={[
                    !isEdit && {
                      required: true,
                      message: 'Please select type!'
                    }
                  ]}
                >
                  <Select
                    placeholder="Select type"
                    options={PLAN_TYPE_OPTIONS}
                    onChange={(e) => {
                      if (e === PLAN_TYPE_KEYS.RECURRING) {
                        form.setFieldValue('applyPermissions', true);
                      }
                    }}
                    disabled={isEdit}
                  />
                </Form.Item>
                <Form.Item
                  className="m-0"
                  name="isActive"
                  valuePropName="checked"
                >
                  <Switch label="Active" disabled={isEdit && isFreeOrGuest} />
                </Form.Item>
                <Form.Item
                  className="m-0"
                  name="showOnPaywall"
                  valuePropName="checked"
                >
                  <Switch label="Show on Paywall" />
                </Form.Item>
                <Form.Item
                  className="m-0"
                  name="applyPermissions"
                  valuePropName="checked"
                  dependencies={['type']}
                >
                  <Switch
                    label="Apply Permissions"
                    disabled={
                      type === PLAN_TYPE_KEYS.RECURRING ||
                      (isEdit && isFreeOrGuest)
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={24} lg={12}>
                <Form.Item className="full-html" label="Html" name="html">
                  <LoadEditor disabled={isViewOnly} className="full-html" />
                </Form.Item>
              </Col>
            </Row>
            {applyPermissions && (
              <div className="listview mt-16 mb-20 plans-permissions-table">
                <fieldset>
                  <legend className="role-legend">Permissions</legend>
                  <Table
                    loading={loading}
                    rowKey={(obj) => obj?.key}
                    columns={columns}
                    bordered={false}
                    dataSource={permissions}
                    pagination={false}
                    className="roles-table"
                  />
                </fieldset>
              </div>
            )}
            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={createLoading || loading}
                    loading={createLoading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  disabled={createLoading || loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditPlans;
