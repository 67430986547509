import { useMutation } from '@apollo/client';
import { Form, Modal } from 'antd';
import React from 'react';
import { authClient } from '../../apollo';
import { GET_WORKSPACES_APPS } from '../../app/components/sidebar/graphql/Queries';
import { Select } from '../videos/components/FormInputs';

const variablesSelector = ({ limit, skip, search }) => ({
  filter: {
    limit,
    skip,
    search
  }
});

const SelectAppModal = ({
  openModal,
  setOpenModal,
  email,
  setEmail,
  title,
  mutation
}) => {
  const [form] = Form.useForm();
  const appId = Form?.useWatch('appId', form);
  const [updateMutation, { loading }] = useMutation(mutation, {
    client: authClient
  });
  const handleCancel = () => {
    setOpenModal(false);
  };

  const handleFinish = (values) => {
    const payload = {
      email,
      appId: values?.appId?.value
    };
    updateMutation({ variables: { data: payload } }).then((res) => {
      if (res?.data) {
        setOpenModal(false);
        setEmail(null);
      }
    });
  };

  return (
    <Modal
      open={openModal}
      onCancel={handleCancel}
      centered
      title={title}
      width={550}
      okText="Send Link"
      closable={false}
      onOk={() => {
        form?.submit();
      }}
      okButtonProps={{ disabled: !appId }}
      confirmLoading={loading}
    >
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        <Form.Item
          label="App"
          name="appId"
          rules={[{ required: true, message: 'Please select app!' }]}
          extra={<b>Note: You will receive email link of selected app</b>}
        >
          <Select
            placeholder="Select app"
            query={GET_WORKSPACES_APPS}
            variablesSelector={variablesSelector}
            dataSelector={(data) => {
              return (
                data?.workspaceApps?.workspaceApps?.map(({ id, name }) => ({
                  label: name,
                  value: id
                })) ?? []
              );
            }}
            keys={{
              data: 'workspaceApps',
              records: 'workspaceApps',
              count: 'count'
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SelectAppModal;
