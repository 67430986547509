import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import AddEditPlans from './AddEditPlans';
import SubscriptionPlans from './SubscriptionPlans';

const SubscriptionPlansWrapper = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES?.SUBSCRIPTION_PLANS}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.SUBSCRIPTION_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <SubscriptionPlans {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.SUBSCRIPTION_PLANS}/add`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.SUBSCRIPTION_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditPlans {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.SUBSCRIPTION_PLANS}/:planId/edit`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.SUBSCRIPTION_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditPlans {...props} />
          </AccessControl>
        )}
      />
    </Switch>
  );
};

export default SubscriptionPlansWrapper;
