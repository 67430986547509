import { gql } from '@apollo/client';

export const GET_COMMUNITIES = gql`
  query communitiesAdmin($filter: CommunitiesFilter, $sort: CommunitiesSort) {
    communitiesAdmin(filter: $filter, sort: $sort) {
      communities {
        id
        slug
        title
        imageUrl
        membersCount
        description
        visibility
        primaryColor
      }
      count
    }
  }
`;

export const GET_COMMUNITY = gql`
  query communityAdmin($where: CommunityWhereUniqueInput!) {
    communityAdmin(where: $where) {
      description
      imageUrl
      metaFooter
      metaHeader
      slug
      primaryColor
      type
      imageUrl
      tags {
        id
        name
      }
      title
      topics {
        id
        name
      }
      visibility
    }
  }
`;

export const GET_USERS = gql`
  query users($filter: UsersFilter) {
    users(filter: $filter) {
      count
      users {
        id
        email
      }
    }
  }
`;

export const GET_COMMUNITY_MEMBERS = gql`
  query CommunityMembersAdmin(
    $filter: CommunityMembersFilter!
    $sort: CommunityMembersSort
  ) {
    communityMembersAdmin(filter: $filter, sort: $sort) {
      communityMembers {
        id
        role
        status
        member {
          id
          firstName
          email
          lastName
          profileImage
          roles
        }
      }
      count
    }
  }
`;
