import { gql } from '@apollo/client';

export const CREATE_PLAN = gql`
  mutation CreateSubscriptionPlan($data: SubscriptionPlanCreateInput!) {
    createSubscriptionPlan(data: $data) {
      message
    }
  }
`;

export const UPDATE_PLAN = gql`
  mutation UpdateSubscriptionPlan(
    $where: SubscriptionPlanWhereInput!
    $data: SubscriptionPlanUpdateInput!
  ) {
    updateSubscriptionPlan(where: $where, data: $data) {
      message
    }
  }
`;

export const DELETE_PLAN = gql`
  mutation DeleteSubscriptionPlan($where: SubscriptionPlanWhereInput!) {
    deleteSubscriptionPlan(where: $where) {
      message
    }
  }
`;
