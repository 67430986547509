import { useQuery } from '@apollo/client';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';
import { useParams } from 'react-router-dom';
import { useApp } from '../../../AppContext';
import { GET_WORKSPACE_APP } from '../graphql/Queries';

const ctx = createContext();

export const usePagePreview = () => useContext(ctx);

const PreviewPageProvider = ({ pageId, children }) => {
  const [loading, setLoading] = useState(true);
  const { id: pageParamId } = useParams();
  const iframeRef = useRef();
  const { state } = useApp();
  const authToken = state?.authToken;
  const id = pageId || pageParamId;

  const { data, loading: loadingWorkspaceApp } = useQuery(GET_WORKSPACE_APP, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        id: state?.app?.id
      }
    },
    onCompleted(response) {
      if (!response?.workspaceApp?.url && !process.env.REACT_APP_WEB_URL) {
        setLoading(false);
      }
    }
  });

  const isWebApp = data?.workspaceApp?.typeKey === 'WEB_APP';

  const webUrl =
    data && isWebApp
      ? data?.workspaceApp?.url || process.env.REACT_APP_WEB_URL || ''
      : process.env.REACT_APP_WEB_URL ?? '';

  const appUUID = state?.app?.value;
  const workspaceUUID = state?.workspace?.value;
  const appId = state?.app?.id;
  const workspaceId = state?.workspace?.id;

  const url = useMemo(
    () =>
      id &&
      authToken &&
      webUrl &&
      appUUID &&
      workspaceUUID &&
      appId &&
      workspaceId &&
      !loadingWorkspaceApp
        ? `${webUrl}/preview?pageId=${id}&token=${authToken}&appUUID=${appUUID}&workspaceUUID=${workspaceUUID}&appId=${appId}&workspaceId=${workspaceId}`
        : null,
    [id, authToken, webUrl, loadingWorkspaceApp]
  );

  const refetch = useCallback(() => {
    if (iframeRef.current) {
      setLoading(true);
      iframeRef.current.src = url;
    }
  }, [url]);

  const onLoad = useCallback(() => {
    if (url) setLoading(false);
  }, [url]);

  return (
    <ctx.Provider
      value={{
        onLoad,
        loading,
        refetch,
        url,
        iframeRef
      }}
    >
      {children}
    </ctx.Provider>
  );
};

export default PreviewPageProvider;
