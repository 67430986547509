import { EditOutlined, EyeFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Select, Tooltip } from 'antd';
import moment from 'moment';
import { ArrowClockwise, PencilSimpleLine } from 'phosphor-react';
import React, { useCallback, useMemo, useState } from 'react';
import videoPlaceholder from '../../assets/images/imagePlaceholder.png';
import {
  CLOUD_FRONT_CACHE_KEYS,
  LIVE_STATUS_COLORS,
  MODULES,
  ROUTES,
  VIDEO_LIVE_FILTERS,
  VIDEO_LIVE_FILTERS_OPTIONS,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { getImageUrl } from '../../common/utils';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import Image from '../../components/Image';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import CustomCard from '../component/card/Cscard';
import { INVALIDATE_CLOUD_FRONT_CACHE } from '../menus/graphql/Mutations';
import { GET_VIDEOS_MODULES } from './graphql/Queries';

const VideoCard = ({
  createdAt,
  id,
  title,
  description,
  imageThumbnail,
  onEdit,
  onPreview,
  isAssetAllowed,
  isLabelAllowed,
  isViewOnly,
  isLive,
  liveStatus
}) => {
  return (
    <CustomCard
      isLive={isLive}
      status={isLive && liveStatus}
      statusColor={isLive && LIVE_STATUS_COLORS[liveStatus]}
      onClick={onPreview}
      alt={title}
      src={getImageUrl(imageThumbnail?.url) ?? videoPlaceholder}
      blurHash={imageThumbnail?.blurhash}
      title={moment(createdAt).format('ll')}
      heading={title}
      description={description}
      primaryOnClick={() => onEdit(id)}
      primary={
        isAssetAllowed &&
        isLabelAllowed &&
        (isViewOnly ? (
          <>
            <EyeFilled size={16} />
            View
          </>
        ) : (
          <>
            <PencilSimpleLine size={16} />
            Edit
          </>
        ))
      }
    />
  );
};

const getColumns = ({
  handleEdit: onEdit,
  isAssetAllowed,
  isLabelAllowed,
  isViewOnly
}) => [
  {
    title: 'Video',
    dataIndex: 'imageThumbnail',
    ellipsis: true,
    render: (value, { title }) => (
      <Image
        className="group-img-table"
        blurHash={value?.blurhash}
        src={
          getImageUrl(value?.url, { height: 50, width: 80 }) ?? videoPlaceholder
        }
        alt={title}
      />
    )
  },
  {
    title: 'Title',
    dataIndex: 'title',
    ellipsis: true
  },
  {
    title: 'Description',
    dataIndex: 'description',
    ellipsis: true
  },
  ...(isAssetAllowed && isLabelAllowed
    ? [
        {
          title: 'Action',
          dataIndex: 'id',
          render: (value) => (
            <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} Video`}>
              <Button
                type="text"
                className="text-btn"
                onClick={() => onEdit(value)}
              >
                {isViewOnly ? <EyeFilled /> : <EditOutlined />}
              </Button>
            </Tooltip>
          )
        }
      ]
    : [])
];

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder,
  isLive
}) => ({
  filter: {
    skip: offset,
    search,
    limit,
    isLive
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ videosAdmin }) => ({
  data: videosAdmin?.videos ?? [],
  count: videosAdmin?.count ?? 0
});

const Videos = ({ history }) => {
  const filterProps = useFilterBar();
  const [liveVideoStatusFilter, setLiveVideoStatusFilter] = useState(
    VIDEO_LIVE_FILTERS.ALL
  );
  const [invalidateCache, { loading: isInvalidating }] = useMutation(
    INVALIDATE_CLOUD_FRONT_CACHE,
    {
      variables: { key: CLOUD_FRONT_CACHE_KEYS.OG_IMAGES }
    }
  );

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isLabelAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isAssetAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const handleShowModal = () => {
    history.push(`${ROUTES?.VIDEOS_MODULE}/add`);
  };

  const handleEdit = useCallback((id) => {
    history.push(`${ROUTES?.VIDEOS_MODULE}/${id}/edit`);
  }, []);

  const columns = useMemo(
    () =>
      getColumns({
        handleEdit,
        isAddEditAllowed,
        isAssetAllowed,
        isLabelAllowed,
        isViewOnly
      }),
    [handleEdit]
  );

  const filters = useMemo(
    () => ({
      ...filterProps.filters,
      ...(liveVideoStatusFilter !== VIDEO_LIVE_FILTERS.ALL && {
        isLive: liveVideoStatusFilter === VIDEO_LIVE_FILTERS.LIVE
      })
    }),
    [filterProps.filters, liveVideoStatusFilter]
  );

  return (
    <>
      <PageHeader
        menu={MODULES?.CONTENT_UNITS}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed && isAssetAllowed && isLabelAllowed}
      />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Videos' }}
        extraFilters={
          <Select
            popupMatchSelectWidth={false}
            options={VIDEO_LIVE_FILTERS_OPTIONS}
            value={liveVideoStatusFilter}
            onChange={(val) => setLiveVideoStatusFilter(val)}
          />
        }
      >
        <Tooltip title="Revalidate OG images" placement="left">
          <Button
            className="text-btn d-flex align-center justify-center"
            type="text"
            icon={<ArrowClockwise size={24} />}
            onClick={invalidateCache}
            loading={isInvalidating}
          />
        </Tooltip>
      </FilterBar>
      <PageList
        filters={filters}
        listMode={filterProps.listMode}
        query={GET_VIDEOS_MODULES}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        GridProps={{
          renderItem: (props) => (
            <VideoCard
              {...props}
              onEdit={handleEdit}
              isAddEditAllowed={isAddEditAllowed}
              isAssetAllowed={isAssetAllowed}
              isLabelAllowed={isLabelAllowed}
              isViewOnly={isViewOnly}
            />
          )
        }}
        TableProps={{ columns }}
      />
    </>
  );
};

export default Videos;
