import { CheckOutlined } from '@ant-design/icons';
import { Button, Card, Image, Select, Space, Typography, theme } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import AudioPlaceholder from '../../../assets/images/audioPlaceholder.jpg';
import DocumentPlaceholder from '../../../assets/images/docPlaceholder.png';
import videoPlaceholder from '../../../assets/images/imagePlaceholder.png';
import Live from '../../../assets/images/live-new.png';
import {
  ASSET_CATEGORY,
  VIDEO_LIVE_FILTERS,
  VIDEO_LIVE_FILTERS_OPTIONS
} from '../../../common/constants';
import { getImageUrl } from '../../../common/utils';
import SelectableModal from '../../../components/SelectableModal';
import { useModal } from '../../videos/components/FormInputs';
import Preview from '../../videos/components/Preview';
import { GET_ASSETS } from '../graphql/Queries';
import CreateAssetModal from './CreateAssetModal';

export const initialAsset = {
  id: '',
  url: ''
};

const DEFAULT_PLACEHOLDER_IMAGE = {
  [ASSET_CATEGORY.VIDEO]: videoPlaceholder,
  [ASSET_CATEGORY.IMAGE]: videoPlaceholder,
  [ASSET_CATEGORY.DOCUMENT]: DocumentPlaceholder,
  [ASSET_CATEGORY.TEXT]: DocumentPlaceholder,
  [ASSET_CATEGORY.AUDIO]: AudioPlaceholder
};

export const getAssetImage = (item, key) =>
  !item
    ? DEFAULT_PLACEHOLDER_IMAGE[key]
    : {
        [ASSET_CATEGORY.VIDEO]:
          item?.serviceVideoThumbnail ?? item?.serviceImageThumbnail,
        [ASSET_CATEGORY.IMAGE]:
          getImageUrl(item?.url, {
            height: 200,
            width: 200
          }) || videoPlaceholder,
        [ASSET_CATEGORY.ICON]:
          getImageUrl(item?.url, {
            height: 200,
            width: 200
          }) || videoPlaceholder,
        [ASSET_CATEGORY.AUDIO]: item?.serviceImageThumbnail ?? AudioPlaceholder
      }[key] ?? DEFAULT_PLACEHOLDER_IMAGE[key];

const SelectAsset = ({
  id,
  value,
  onChange,
  dataSelector,
  modalTitle,
  categoryKey,
  btnText,
  multiple = false,
  previewImageKey = '',
  allowClear = true,
  isAssetEditAllowed = true,
  isAssetViewAllowed = true,
  disabled = false,
  IsTableView = false,
  showLive,
  disableFilters,
  defaultFilters,
  contentType = null,
  isMenu = false
}) => {
  const { colorText } = theme.useToken().token;
  const [liveVideoStatusFilter, setLiveVideoStatusFilter] = useState(
    VIDEO_LIVE_FILTERS.ALL
  );

  const filters = useMemo(
    () => ({
      ...(showLive &&
        categoryKey === ASSET_CATEGORY.VIDEO &&
        liveVideoStatusFilter !== VIDEO_LIVE_FILTERS.ALL && {
          isLive: liveVideoStatusFilter === VIDEO_LIVE_FILTERS.LIVE
        })
    }),
    [liveVideoStatusFilter, showLive, categoryKey]
  );

  useEffect(() => {
    if (defaultFilters && 'isLive' in defaultFilters) {
      setLiveVideoStatusFilter(
        defaultFilters?.isLive
          ? VIDEO_LIVE_FILTERS.LIVE
          : VIDEO_LIVE_FILTERS.NON_LIVE
      );
    }
  }, [defaultFilters]);

  const { open, openModal, closeModal } = useModal();
  const [showAssetModal, setShowAssetModal] = useState(false);
  const [newAsset, setNewAsset] = useState(null);

  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value.find((items) => items.id === item.id);
      if (isPresent) {
        onChange(value.filter((items) => items.id !== item.id));
      } else {
        onChange([...value, dataSelector(item)]);
      }
      return;
    }
    onChange(dataSelector(item));
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value.filter((items) => items.id !== item.id));
    } else {
      onChange({ ...initialAsset });
    }
  };

  useEffect(() => {
    if (!isEmpty(newAsset)) {
      if (newAsset?.asset) {
        if (isMenu && newAsset?.data?.contentType === 'image/svg+xml') {
          if (multiple) {
            const isPresent = value.find(
              (items) => items.id === newAsset?.data.id
            );
            if (isPresent) {
              onChange(value.filter((items) => items.id !== newAsset?.data.id));
            } else {
              onChange([...value, dataSelector(newAsset?.data)]);
            }
          } else {
            onChange(dataSelector(newAsset?.data));
          }
        } else if (!isMenu) {
          if (multiple) {
            const isPresent = value.find(
              (items) => items.id === newAsset?.data.id
            );
            if (isPresent) {
              onChange(value.filter((items) => items.id !== newAsset?.data.id));
            } else {
              onChange([...value, dataSelector(newAsset?.data)]);
            }
          } else {
            onChange(dataSelector(newAsset?.data));
          }
        }
      }
    }
  }, [newAsset]);

  return (
    <>
      {showAssetModal && (
        <CreateAssetModal
          showModal={showAssetModal}
          setShowModal={setShowAssetModal}
          assetType={categoryKey}
          setNewAsset={setNewAsset}
          isMenu={isMenu}
        />
      )}
      <SelectableModal
        title={modalTitle}
        open={open}
        onClose={closeModal}
        query={GET_ASSETS}
        categoryKey={categoryKey}
        variablesSelector={(offset, limit, search) => ({
          filter: {
            categoryKey,
            limit,
            skip: offset,
            search,
            ...(categoryKey === ASSET_CATEGORY.VIDEO && { status: 'READY' }),
            ...filters,
            ...(contentType && {
              contentType
            })
          }
        })}
        dataSelector={(data) => data?.assets?.assets || []}
        keys={{
          data: 'assets',
          records: 'assets',
          count: 'count'
        }}
        assetModal
        isAssetEditAllowed={isAssetEditAllowed}
        newAsset={newAsset}
        setShowAssetModal={setShowAssetModal}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value.map((val) => val.id).includes(item.id)
            : value?.id === item?.id;

          return (
            <Card
              className={`selectable-modal-card cs-card ${
                isSelected ? 'active' : ''
              }`}
              cover={
                <>
                  {isSelected && (
                    <span className="checked-icon">
                      <CheckOutlined />
                    </span>
                  )}
                  <Image
                    preview={false}
                    className="label-poster"
                    src={getAssetImage(item, categoryKey)}
                    alt=""
                  />
                </>
              }
              onClick={() => handleCardSelect(item)}
            >
              {item?.isLive && (
                <img className="live-tag" src={Live} alt="Live" />
              )}
              <Card.Meta
                title={
                  <span title={item.title} className="label-title">
                    {item.title}
                  </span>
                }
                description={
                  <Typography.Paragraph
                    className="asset-description"
                    ellipsis={{ rows: 2 }}
                  >
                    {moment(item.createdAt).format('ll')}
                  </Typography.Paragraph>
                }
              />
            </Card>
          );
        }}
        renderFilters={() => (
          <Select
            popupMatchSelectWidth={false}
            options={VIDEO_LIVE_FILTERS_OPTIONS}
            value={liveVideoStatusFilter}
            onChange={(val) => setLiveVideoStatusFilter(val)}
            disabled={disableFilters}
          />
        )}
        filters={filters}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value?.length > 0 &&
              value?.map((item) => (
                <Preview
                  {...(allowClear && {
                    onRemove: () => handleRemove(item)
                  })}
                  IsTableView={IsTableView}
                  key={item.id}
                  bgImg={
                    categoryKey === ASSET_CATEGORY.AUDIO
                      ? item.url ?? DEFAULT_PLACEHOLDER_IMAGE[categoryKey]
                      : item[previewImageKey] ||
                        DEFAULT_PLACEHOLDER_IMAGE[categoryKey]
                  }
                >
                  {showLive && item?.isLive && <Preview.Live />}
                  <Preview.Title color={colorText}>{item.title}</Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                IsTableView={IsTableView}
                {...(allowClear && {
                  onRemove: handleRemove
                })}
                bgImg={
                  categoryKey === ASSET_CATEGORY.DOCUMENT
                    ? DEFAULT_PLACEHOLDER_IMAGE[categoryKey]
                    : value?.url || DEFAULT_PLACEHOLDER_IMAGE[categoryKey]
                }
              >
                {showLive && value?.isLive && <Preview.Live />}
                {value.title && (
                  <Preview.Title color={colorText}>{value.title}</Preview.Title>
                )}
              </Preview>
            )}
          </>
        )}
        <Button
          id={id}
          htmlType="button"
          onClick={openModal}
          disabled={disabled || (!isAssetViewAllowed && !isAssetEditAllowed)}
          className={IsTableView ? 'table-view-btn' : ''}
        >
          {value?.id ? 'Change' : 'Select'} {btnText}
        </Button>
      </Space>
    </>
  );
};

export default SelectAsset;
