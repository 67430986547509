import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_POST = gql`
  mutation updatePostAdmin(
    $where: PostWhereUniqueInput!
    $data: UpdatePostInput!
  ) {
    updatePostAdmin(where: $where, data: $data) {
      message
    }
  }
`;
