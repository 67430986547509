import { CheckOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Select as AntdSelect, Button, Form, Input, Space } from 'antd';
import map from 'lodash/map';
import React, { useEffect, useMemo } from 'react';
import * as urlSlug from 'url-slug';
import { useApp } from '../../AppContext';
import {
  COMMUNITY_TYPES,
  COMMUNITY_VISIBILITY,
  COMMUNITY_VISIBILITY_TYPES,
  MAX_LENGTHS,
  MODULES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import PageHeader from '../../components/PageHeader';
import useCheckPermission from '../../hooks/useCheckPermission';
import useStaticData from '../../hooks/useStaticData';
import { SlugInput } from '../labels/topics/components/FormInputs';
import { Select } from '../videos/components/FormInputs';
import Preview from '../videos/components/Preview';
import { GET_TAGS, GET_TOPICS } from '../videos/graphql/Queries';
import { SelectUsers } from './components';
import { CREATE_COMMUNITY, UPDATE_COMMUNITY } from './graphql/Mutations';
import { GET_COMMUNITY } from './graphql/Queries';

const initialValues = {
  title: '',
  description: '',
  slug: '',
  tags: [],
  topics: [],
  zipCode: '',
  metaHeader: '',
  metaFooter: '',
  visibility: COMMUNITY_VISIBILITY.PUBLIC,
  imageUrl: '',
  primaryColor: ''
};

const ImagePicker = ({ data = [], value, onChange }) => {
  return (
    <div className="images-wrapper">
      {data?.length > 0
        ? data?.map((url, index) => {
            const active = value === url;
            return (
              <div
                key={url}
                className={`image-wrapper ${active ? 'active' : ''}`}
                onClick={() => onChange(url)}
              >
                {active && (
                  <span className="checked-icon">
                    <CheckOutlined />
                  </span>
                )}
                <img src={url} alt={`community ${index + 1}`} />
              </div>
            );
          })
        : 'No Images Found!'}
    </div>
  );
};

const ColorPicker = ({ data = [], value, onChange }) => {
  return (
    <div className="d-flex flex-wrap">
      {data?.length > 0
        ? data?.map((color) => (
            <div
              key={color}
              style={{
                // Used inline style as its dynamic value
                backgroundColor: color
              }}
              className={`color-list ${value === color ? 'active-color' : ''}`}
              onClick={() => onChange(color)}
            />
          ))
        : 'No Colors Found!'}
    </div>
  );
};

export default function AddEditCommunity({ history, match: { params } }) {
  const [form] = Form.useForm();
  const { id } = params;
  const isEdit = Boolean(id);
  const app = useApp();
  const visibility = Form.useWatch(['visibility'], form);

  const { data: communityData, loading: loadingCommunityData } = useStaticData(
    `${app?.state?.workspace?.id}/config`
  );

  const [
    fetchCommunityDetails,
    { loading: fetchingDetails, data }
  ] = useLazyQuery(GET_COMMUNITY, {
    fetchPolicy: 'network-only'
  });

  const [addUpdateCommunity, { loading }] = useMutation(
    isEdit ? UPDATE_COMMUNITY : CREATE_COMMUNITY
  );

  useEffect(() => {
    if (isEdit && id) {
      fetchCommunityDetails({
        variables: {
          where: {
            id
          }
        }
      }).then((res) => {
        const community = res.data?.communityAdmin;
        if (community) {
          const slug = community?.slug.startsWith('/')
            ? community?.slug
            : `/${community?.slug}`;
          form.setFieldsValue({
            title: community?.title ?? '',
            description: community?.description ?? '',
            imageUrl: community?.imageUrl ?? '',
            primaryColor: community?.primaryColor ?? '',
            slug,
            visibility: community?.visibility ?? COMMUNITY_VISIBILITY.PUBLIC,
            tags:
              community?.tags?.map((tag) => ({
                label: tag?.name,
                value: tag?.id
              })) ?? [],
            topics:
              community?.topics?.length > 0
                ? map(community?.topics, (item) => {
                    return {
                      value: item?.id,
                      label: item?.name
                    };
                  })
                : [],
            zipCode: community?.zipCode ?? '',
            metaHeader: community?.metaHeader ?? '',
            metaFooter: community?.metaFooter ?? ''
          });
        }
      });
    }
  }, [isEdit, id, form, fetchCommunityDetails]);

  const handleCancel = () => {
    history.replace(ROUTES?.COMMUNITY_MODULE);
  };

  const handleSubmit = ({
    slug,
    topics,
    tags,
    zipCode,
    ownerId,
    visibility: visibilityValue,
    ...values
  }) => {
    const payload = {
      ...values,
      topics: topics?.map(({ value }, index) => ({
        order: index + 1,
        topicId: value
      })),
      tags: tags?.map(({ value }, index) => ({
        order: index + 1,
        tagId: value
      })),
      slug: slug?.startsWith('/') ? slug?.substring(1) : slug,
      ...(!isEdit && {
        zipCode,
        ownerId: ownerId?.value,
        visibility: visibilityValue
      })
    };

    addUpdateCommunity({
      variables: {
        ...(isEdit && { where: { id } }),
        data: payload
      }
    }).then(() => {
      history.push(ROUTES?.COMMUNITY_MODULE);
    });
  };

  const handleTitleChange = (e) => {
    form.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const image = useMemo(
    () =>
      data?.communityAdmin?.type === COMMUNITY_TYPES?.LOCATION_BASED ? (
        <Preview allowClear={false} bgImg={data?.communityAdmin?.imageUrl} />
      ) : (
        <ImagePicker data={communityData?.COMMUNITY_IMAGE_URLS?.value || []} />
      ),
    [data, communityData]
  );

  return (
    <>
      <PageHeader menu={MODULES?.CONTENT_UNITS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form community-form"
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
            disabled={fetchingDetails || isViewOnly}
          >
            {!isEdit && (
              <Form.Item name="visibility" hidden>
                <Input />
              </Form.Item>
            )}
            <Form.Item
              label="Title"
              name="title"
              required
              rules={[
                formValidatorRules?.required('Please enter title!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <Input placeholder="Enter title" onChange={handleTitleChange} />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea placeholder="Enter description" />
            </Form.Item>
            <Form.Item
              label="Community Type"
              name="visibility"
              rules={[
                { required: true, message: 'Please select community type!' }
              ]}
            >
              <AntdSelect
                options={COMMUNITY_VISIBILITY_TYPES.map(({ label, value }) => ({
                  label,
                  value
                }))}
                placeholder="Select type"
                disabled={isEdit}
              />
            </Form.Item>
            {!isEdit && visibility === COMMUNITY_VISIBILITY.PRIVATE && (
              <Form.Item
                label="Community Owner"
                name="ownerId"
                rules={[{ required: true, message: 'Please select owner!' }]}
              >
                <SelectUsers placeholder="Select Owner" mode="" returnId />
              </Form.Item>
            )}
            <Form.Item
              label="Slug"
              name="slug"
              rules={[
                {
                  required: true,
                  message: 'Please enter slug!'
                },
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <SlugInput />
            </Form.Item>

            <Form.Item label="Image" name="imageUrl">
              {loadingCommunityData ? 'Loading...' : image}
            </Form.Item>
            <Form.Item label="Color" name="primaryColor">
              {loadingCommunityData ? (
                'Loading...'
              ) : (
                <ColorPicker data={communityData?.COMMUNITY_COLORS?.value} />
              )}
            </Form.Item>
            <Form.Item name="topics" label="Topic">
              <Select
                mode="multiple"
                placeholder="Select topic"
                query={GET_TOPICS}
                variablesSelector={(filter) => ({ filter })}
                dataSelector={(res) =>
                  res?.topicsAdmin?.topics?.map(({ id: value, name }) => ({
                    label: name,
                    value
                  })) ?? 0
                }
                keys={{
                  data: 'topicsAdmin',
                  records: 'topics',
                  count: 'count'
                }}
              />
            </Form.Item>
            <Form.Item name="tags" label="Tags">
              <Select
                mode="multiple"
                placeholder="Select tags"
                query={GET_TAGS}
                variablesSelector={(filter) => ({ filter })}
                dataSelector={(res) =>
                  res?.tagsAdmin?.tags?.map(({ id: value, name }) => ({
                    label: name,
                    value
                  })) ?? []
                }
                keys={{
                  data: 'tagsAdmin',
                  records: 'tags',
                  count: 'count'
                }}
              />
            </Form.Item>

            {!isEdit && (
              <Form.Item label="Zipcode" name="zipCode">
                <Input placeholder="Enter zipcode" />
              </Form.Item>
            )}

            <Form.Item
              name="metaHeader"
              label="Meta Header"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta header" />
            </Form.Item>
            <Form.Item
              name="metaFooter"
              label="Meta Footer"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta footer" />
            </Form.Item>

            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={loading || fetchingDetails}
                    loading={loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
