import { gql } from '@apollo/client';

export const COMMON_SUBSCRIPTION_FIELDS = gql`
  fragment SubscriptionPlan on SubscriptionPlan {
    id
    key
    order
    name
    description
    html
    workspaceId
    type
    frequency
    amount {
      usd {
        price
      }
    }
    refData
    metaData
    permissions {
      key
      allowed
      applyLimit
      frequency
      key
      limit
      errorMessage
      limitMessage
      errorTitle
      limitTitle
    }
    isActive
    isDefault
    showOnPaywall
    createdAt
    updatedAt
    applyPermissions
  }
`;

export const GET_SUBSCRIPTION_PLANS = gql`
  ${COMMON_SUBSCRIPTION_FIELDS}
  query subscriptionPlans(
    $filter: SubscriptionPlansFilter
    $sort: SubscriptionPlansSort
  ) {
    subscriptionPlans(filter: $filter, sort: $sort) {
      count
      subscriptionPlans {
        ...SubscriptionPlan
      }
    }
  }
`;

export const SUBSCRIPTION_PERMISSIONS = gql`
  query subscriptionPlanPermissions {
    subscriptionPlanPermissions {
      description
      group
      id
      key
      type
    }
  }
`;

export const GET_SUBSCRIPTION_PLAN = gql`
  ${COMMON_SUBSCRIPTION_FIELDS}
  query subscriptionPlan($where: SubscriptionPlanWhereInput!) {
    subscriptionPlan(where: $where) {
      ...SubscriptionPlan
    }
  }
`;
