import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import AddEditAnalytics from './AddEditAnalytics';
import AnalyticsModule from './AnalyticsModule';

const AnalyticsModuleWrapper = ({ linksRef }) => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES?.ANALYTICS_MODULE}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.ANALYTICS,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AnalyticsModule {...props} linksRef={linksRef} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.ANALYTICS_MODULE}/add`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.ANALYTICS,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditAnalytics linksRef={linksRef} {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.ANALYTICS_MODULE}/:analyticsId/edit`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.ANALYTICS,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditAnalytics linksRef={linksRef} {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.ANALYTICS_MODULE}/:analyticsId`}
        render={() => <Redirect to={ROUTES?.ANALYTICS_MODULE} />}
      />
    </Switch>
  );
};

export default AnalyticsModuleWrapper;
