import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space } from 'antd';
import React, { useEffect } from 'react';
import {
  MODULES,
  REGEX,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import PageHeader from '../../components/PageHeader';
import useCheckPermission from '../../hooks/useCheckPermission';
import {
  CREATE_ANALYTIC_LINK,
  UPDATE_ANALYTIC_LINK
} from './graphql/Mutations';
import { GET_ANALYTICS_LINK } from './graphql/Queries';

const { TextArea } = Input;
const AddEditAnalytics = ({ history, match: { params }, linksRef }) => {
  const [form] = Form.useForm();
  const { analyticsId } = params;
  const isEdit = !!analyticsId;

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ANALYTICS,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ANALYTICS,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const [addUpdateAnalyticLink, { loading }] = useMutation(
    isEdit ? UPDATE_ANALYTIC_LINK : CREATE_ANALYTIC_LINK,
    { onError() {} }
  );

  const [fetchAnalyticLink, { loading: fetchingDetails }] = useLazyQuery(
    GET_ANALYTICS_LINK,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        if (res?.analyticLink) {
          form.setFieldsValue({ ...res?.analyticLink });
        }
      },
      onError() {}
    }
  );

  useEffect(() => {
    if (isEdit) {
      fetchAnalyticLink({
        variables: {
          where: {
            id: analyticsId
          }
        }
      });
    }
  }, []);

  const handleSubmit = (formValues) => {
    const payload = {
      ...formValues
    };

    if (isEdit) delete payload?.key;
    addUpdateAnalyticLink({
      variables: {
        data: payload,
        ...(isEdit && {
          where: {
            id: analyticsId
          }
        })
      }
    }).then((res) => {
      if (res.data) {
        linksRef?.current?.refetchLinks();
        history.push(ROUTES.ANALYTICS_MODULE);
      }
    });
  };

  const handleCancel = () => {
    history.push(ROUTES.ANALYTICS_MODULE);
  };

  return (
    <div>
      <PageHeader menu={MODULES?.WORKSPACE_SETTINGS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            className="add-edit-form"
            layout="vertical"
            onFinish={handleSubmit}
            disabled={isViewOnly || fetchingDetails || loading}
            form={form}
          >
            <Form.Item
              label="Key"
              name="key"
              required
              rules={[
                {
                  validator(rule, value) {
                    if (!value) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise?.reject('Please enter a key!');
                    }
                    if (!REGEX?.KEY_VALUE?.test(value)) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise?.reject('Should be a valid key!');
                    }
                    return Promise?.resolve();
                  }
                },
                // need to set 100 as per requirement
                formValidatorRules?.maxLength(100)
              ]}
            >
              <Input placeholder="Enter key" disabled={isEdit} />
            </Form.Item>
            <Form.Item
              label="Label"
              name="label"
              required
              rules={[formValidatorRules?.required('Please enter label!')]}
            >
              <Input placeholder="Enter label" />
            </Form.Item>
            <Form.Item
              name="value"
              label="iFrame embed"
              required
              rules={[formValidatorRules?.required('Please enter iFrame!')]}
            >
              <TextArea rows={8} placeholder="Enter iFrame embed" />
            </Form.Item>
            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={loading}
                    loading={loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddEditAnalytics;
