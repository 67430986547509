import { gql } from '@apollo/client';

export const CREATE_ANALYTIC_LINK = gql`
  mutation CreateAnalyticLink($data: CreateAnalyticLinkInput!) {
    createAnalyticLink(data: $data) {
      message
    }
  }
`;

export const UPDATE_ANALYTIC_LINK = gql`
  mutation UpdateAnalyticLink(
    $where: AnalyticLinkWhereInput!
    $data: UpdateAnalyticLinkInput!
  ) {
    updateAnalyticLink(where: $where, data: $data) {
      message
    }
  }
`;

export const DELETE_ANALYTIC_LINK = gql`
  mutation DeleteAnalyticLink($where: AnalyticLinkWhereInput!) {
    deleteAnalyticLink(where: $where) {
      message
    }
  }
`;
