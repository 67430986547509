import React from 'react';
import { Select } from '../../videos/components/FormInputs';
import { GET_USERS } from '../graphql/Queries';

// eslint-disable-next-line import/prefer-default-export
export function SelectUsers({ returnId = false, ...restProps }) {
  return (
    <Select
      mode="multiple"
      placeholder="Select Users"
      query={GET_USERS}
      popupMatchSelectWidth={false}
      variablesSelector={(filter) => ({
        filter: { isDeleted: false, isActive: true, ...filter }
      })}
      dataSelector={(data) =>
        data?.users?.users?.map(({ email, id }) => ({
          label: email,
          value: returnId ? id : email
        })) ?? 0
      }
      keys={{
        data: 'users',
        records: 'users',
        count: 'count'
      }}
      {...restProps}
    />
  );
}
