import { gql } from '@apollo/client';

export const GET_ANALYTICS_LINKS = gql`
  query AnalyticLinks(
    $options: AnalyticLinksOption!
    $filters: AnalyticLinksFilterInput
  ) {
    analyticLinks(options: $options, filters: $filters) {
      count
      analyticLinks {
        id
        description
        key
        label
        value
      }
    }
  }
`;

export const GET_ANALYTICS_LINK = gql`
  query AnalyticLink($where: AnalyticLinkWhereInput!) {
    analyticLink(where: $where) {
      id
      description
      key
      label
      value
    }
  }
`;
