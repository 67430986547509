import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import Analytics from './Analytics';

const AnalyticsWrapper = () => {
  return (
    <Switch>
      <Route
        exact
        path={`${ROUTES?.ANALYTICS}/:analyticsId`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.ANALYTICS,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <Analytics {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.ANALYTICS}`}
        render={() => <Redirect to={ROUTES?.ANALYTICS_MODULE} />}
      />
    </Switch>
  );
};

export default AnalyticsWrapper;
