import { CloseOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';
import Live from '../../../assets/images/live-new.png';

const Preview = ({ bgImg, children, onRemove, IsTableView }) => {
  return (
    <div className={`preview-card ${IsTableView ? 'table-view-asset' : ''}`}>
      <img alt="" src={bgImg} />
      {onRemove && (
        <button type="button" className="remove-icon" onClick={onRemove}>
          <CloseOutlined />
        </button>
      )}
      {children}
    </div>
  );
};

Preview.Live = () => {
  return <img className="live-tag" src={Live} alt="Live" />;
};

Preview.Title = ({ className = 'title-line-clamp', color, children }) => {
  return (
    <Typography.Text
      title={children}
      className={`${className}`}
      style={{
        color
      }}
    >
      {children}
    </Typography.Text>
  );
};

export default Preview;
