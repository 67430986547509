import { gql } from '@apollo/client';

export const CREATE_FORM = gql`
  mutation CreateForm($data: CreateFormInput!) {
    createForm(data: $data) {
      message
    }
  }
`;

export const UPDATE_FORM = gql`
  mutation UpdateForm($where: FormWhereUniqueInput!, $data: UpdateFormInput!) {
    updateForm(where: $where, data: $data) {
      message
    }
  }
`;
