import axios from 'axios';
import { encode } from 'blurhash';
import dayjs from 'dayjs';
import { isFunction, map, memoize } from 'lodash';
import moment from 'moment';
import client from '../apollo';
import { messageContext } from '../components/AppComponentContainer';
import { GET_SIGNED_URL } from '../components/graphql/Mutation';
import api from './api';
import {
  AUDIO_UPLOAD_PLATFORMS,
  REGEX,
  VIDEO_UPLOAD_PLATFORMS,
  defaultDateFormat
} from './constants';

// Portal related methods
export const injectUsingPortal = (portalId) =>
  // eslint-disable-next-line no-undef
  document?.getElementById(portalId);

export const isPortalIdExists = (portalId) => !!injectUsingPortal(portalId);

// Check for document Id's exists
export const getElementFromDocumentId = (portalId) =>
  // eslint-disable-next-line no-undef
  document?.getElementById(portalId);

export const isDocumentIdExist = (portalId) =>
  !!getElementFromDocumentId(portalId);
// Check for document Id's exists end

export const formatDate = (
  dateTime,
  format = `${defaultDateFormat} hh:mm A`
) => {
  if (dateTime && moment && format) {
    return moment(dateTime)?.format(format);
  }

  return dateTime;
};

export const formValidatorRules = {
  required: (validationMessage) => ({
    async validator(_, value) {
      if (!value?.trim()) {
        throw new Error(validationMessage);
      }
    }
  }),
  email: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('The input is not valid E-mail!');
      }
      return Promise?.resolve();
    }
  }),
  name: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.NAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please enter valid name');
      }
      return Promise?.resolve();
    }
  }),
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    }
  }),
  maxLength: (length, validationMessage, check) => ({
    async validator(_, value) {
      if (
        (isFunction(check) && check(value)) ||
        (!!value && value?.length > length)
      ) {
        throw new Error(
          validationMessage ?? `Max ${length} characters allowed`
        );
      }
    }
  }),
  maxNumberAllowed: (allowedLimit, validationMessage) => ({
    async validator(_, value) {
      if (Number(value) > allowedLimit) {
        throw new Error(validationMessage ?? `Max ${allowedLimit} are allowed`);
      }
    }
  }),
  username: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.USERNAME?.test(value)) {
        return Promise?.reject(
          new Error('Only small case alphabets & numbers allowed')
        );
      }
      return Promise?.resolve();
    }
  }),
  domain: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.DOMAIN?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please enter valid domain');
      }
      return Promise?.resolve();
    }
  }),
  min: ({ length = 0, message }) => ({
    validator(rule, value) {
      if (value && value?.trim()?.length < length) {
        return Promise.reject(
          message ?? `Value must be at least ${length} characters long!`
        );
      }
      return Promise?.resolve();
    }
  })
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj?.setUTCHours(timeObj?.getUTCHours());
  formattedDateTime = new Date(formattedDateTime)?.setUTCMinutes(
    timeObj?.getUTCMinutes()
  );
  formattedDateTime = new Date(formattedDateTime)?.toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPhoneNumberWithoutMask = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');
  if (cleaned) return cleaned;
  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice)?.toLocaleString('en', {
    style: 'currency',
    currency: 'USD'
  });
};

export const formItemProps = { normalize: (value) => value?.trim() };

// Note : Function to upload on s3 bucket
export async function fileUpload(signedUrl, image, onUploadProgress) {
  try {
    return new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      const xhr = new XMLHttpRequest();
      xhr?.open('PUT', signedUrl);
      xhr?.setRequestHeader('Content-Type', image?.type);
      xhr?.addEventListener('readystatechange', function () {
        if (this?.readyState === 4) {
          resolve(xhr?.response);
        }
      });
      if (onUploadProgress) {
        xhr.upload.onprogress = (e) => {
          let percentComplete = 0;
          percentComplete = Math?.ceil((e?.loaded / e?.total) * 100);
          onUploadProgress(percentComplete);
        };
      }
      xhr?.send(image);
    });
  } catch (error) {
    messageContext?.error(error?.message);
  }
}

const peertubeUrlConvertor = (url) => {
  const urlObj = new URL(url);
  const accessToken = urlObj.searchParams.get('access_token');
  urlObj.searchParams.delete('access_token');
  const uploadUrl = urlObj.href;

  return {
    url: uploadUrl,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
};

const bunnyStreamUrlConvertor = (url) => {
  const urlObj = new URL(url);
  const accessKey = urlObj.searchParams.get('AccessKey');
  urlObj.searchParams.delete('AccessKey');
  const uploadUrl = urlObj.href;

  return {
    url: uploadUrl,
    headers: {
      AccessKey: accessKey
    }
  };
};

const getVideoUploadUrlsAndHeaders = {
  [VIDEO_UPLOAD_PLATFORMS.PEERTUBE]: peertubeUrlConvertor,
  [AUDIO_UPLOAD_PLATFORMS.PEERTUBE]: peertubeUrlConvertor,
  [VIDEO_UPLOAD_PLATFORMS.BUNNY_STREAM]: bunnyStreamUrlConvertor
};

export async function uploadToPlatform(
  url,
  file,
  onUploadProgress,
  platform = VIDEO_UPLOAD_PLATFORMS.PEERTUBE
) {
  if (!url) return;
  const getData = getVideoUploadUrlsAndHeaders[platform];
  const { url: uploadUrl, headers = {} } = isFunction(getData)
    ? getData(url)
    : { url };

  try {
    return new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      const xhr = new XMLHttpRequest();
      xhr?.open('PUT', uploadUrl);
      xhr?.setRequestHeader('Content-Type', file?.type);
      Object.entries(headers).forEach(([key, value]) => {
        xhr?.setRequestHeader(key, value);
      });
      xhr?.addEventListener('readystatechange', function () {
        if (this?.readyState === 4) {
          resolve(xhr?.response);
        }
      });
      if (onUploadProgress) {
        xhr.upload.onprogress = (e) => {
          let percentComplete = 0;
          percentComplete = Math?.ceil((e?.loaded / e?.total) * 100);
          onUploadProgress(percentComplete);
        };
      }
      xhr?.send(file);
    });
  } catch (error) {
    messageContext?.error(error?.message);
  }
}

export const getSignedUrl = async (fileObj) => {
  const fileName = fileObj?.name;

  const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);
  const key = `${fileName}`;

  const response = await client?.mutate({
    mutation: GET_SIGNED_URL,
    variables: {
      action: 'write',
      data: {
        extension: `.${extension}`,
        contentType: fileObj?.type,
        key
      }
    }
  });
  if (response) {
    return response?.data;
  }
  return null;
};

export const uploadImage = async (signedRequest, fileObj) => {
  await api(signedRequest, {
    method: 'PUT',
    data: fileObj?.originFileObj || fileObj,
    headers: {
      'Content-Type': fileObj?.type
    }
  });
};

export const fetchImage = async (fileObj) => {
  const fileName = fileObj?.name;
  const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);
  const key = `${fileName}`;

  const response = await client?.mutate({
    mutation: GET_SIGNED_URL,
    variables: {
      action: 'read',
      data: {
        extension: `.${extension}`,
        contentType: fileObj?.type,
        key
      }
    }
  });
  if (response) {
    return response?.data;
  }
  return null;
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result);
    reader.onerror = (error) => reject(error);
  });

export const getTimeFromMins = (mins) => {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;
  return `${hours}h ${minutes}m`;
};

export const getBase64File = (img, callback) => {
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader?.addEventListener('load', () => callback(reader?.result));
  reader?.readAsDataURL(img);
};

export const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    messageContext?.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file?.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    messageContext?.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

export const getImageUrl = (url, { height = 211, width = 377 } = {}) => {
  if (!url) return url;
  try {
    const newUrl = new URL(url);
    newUrl.searchParams.set('height', height);
    newUrl.searchParams.set('width', width);

    return newUrl.href;
  } catch (err) {
    return url;
  }
};

export class Blurhash {
  static async encode(file) {
    if (!file) return '';

    const imageUrl = URL.createObjectURL(file);

    const img = await Blurhash.loadImage(imageUrl);
    const clampedSize = Blurhash.getClampedSize(img.width, img.height, 64);
    // eslint-disable-next-line no-shadow
    const imageData = Blurhash.getImageData(
      img,
      clampedSize.width,
      clampedSize.height
    );

    const blurhash = encode(
      imageData.data,
      imageData.width,
      imageData.height,
      4,
      4
    );

    return blurhash;
  }

  static getClampedSize(width, height, max) {
    if (width >= height && width > max) {
      return { width: max, height: Math.round((height / width) * max) };
    }

    if (height > width && height > max) {
      return { width: Math.round((width / height) * max), height: max };
    }

    return { width, height };
  }

  static async loadImage(src) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = (...args) => reject(args);
      img.src = src;
    });
  }

  static getImageData(image, resolutionX, resolutionY) {
    // eslint-disable-next-line no-undef
    const canvas = document.createElement('canvas');
    canvas.width = resolutionX;
    canvas.height = resolutionY;
    const context = canvas.getContext('2d');
    context.drawImage(image, 0, 0, resolutionX, resolutionY);
    return context.getImageData(0, 0, resolutionX, resolutionY);
  }
}

export const isDarkMode = () =>
  // eslint-disable-next-line no-undef
  window?.matchMedia?.('(prefers-color-scheme: dark)')?.matches;

export const getConversationTime = (time, zone) => {
  let zoneFormat = 'z';
  const timeWithZone = moment(time).tz(zone);
  const abbr = timeWithZone.zoneAbbr();

  if (/[0-9]/.test(abbr)) zoneFormat = 'Z';
  return timeWithZone.format(`h:mm A (${zoneFormat})`);
};

function getFilenameFromContentDisposition(res) {
  let filename = null;

  const disposition = res.headers.get('content-disposition');

  if (disposition?.includes('attachment')) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches?.[1]) {
      filename = matches[1].replace(/['"]/g, '');
      // Sometimes the filename comes in a URI encoded format so decode it
      filename = decodeURIComponent(filename);
      // Sometimes the filename starts with UTF-8, remove that
      filename = filename.replace(/^UTF-8/i, '').trim();
    }
  }

  return filename;
}

export async function getFileFromUrl(url) {
  const fileRes = await fetch(url);
  const blob = await fileRes.blob();

  let fileName = getFilenameFromContentDisposition(fileRes);
  if (!fileName) {
    fileName = url.split('/').pop();
  }

  // eslint-disable-next-line no-undef
  const file = new File([blob], fileName, {
    type: blob.type
  });

  return file;
}

export const staticApi = axios.create({
  baseURL: `${process.env.REACT_APP_STATIC_DATA_URL}/static`
});

export const status = {
  PENDING: 'Pending',
  PROCESSING: 'Processing',
  ERRORED: 'Errored',
  READY: 'Ready',
  SUCCESS: 'Success',
  FAILED: 'Failed',
  DUPLICATE: 'Duplicate',
  REJECTED: 'Rejected',
  COMPLETED: 'Completed'
};

export const getFilenameFormUrl = memoize((url) => {
  if (!url) return null;
  try {
    const { pathname } = new URL(url);
    const paths = pathname.split('/');
    return paths[paths.length - 1];
  } catch (err) {
    return null;
  }
});

export const getFeatureVideoData = (video) => ({
  id: video?.id,
  date: video?.createdAt
    ? moment(video?.createdAt).format(defaultDateFormat)
    : '',
  tagName: video?.topics?.[0]?.name ?? '',
  tagHref: `/`,
  heading: video?.title ?? '',
  subHeading: video?.speakers
    ?.map(
      (author) =>
        [author?.firstName, author?.lastName]?.filter(Boolean)?.join(' ') ?? ''
    )
    ?.join(', '),
  description: video?.description ?? '',
  collections: video?.collections || [],
  tagData:
    video?.tags?.map(({ key, name }) => ({
      id: key,
      name,
      href: `/`
    })) ?? [],
  tagBackground: video?.topics?.[0]?.primaryColor ?? 'bg-n-200',
  src: video?.imageThumbnail?.url ?? '',
  blurhash: video?.imageThumbnail?.blurhash,
  href: `/`
});

export const getFeaturePodcastData = (podcast) => ({
  id: podcast?.id,
  date: podcast?.createdAt
    ? moment(podcast?.createdAt).format(defaultDateFormat)
    : '',
  tagName: podcast?.topics?.[0]?.name ?? '',
  tagHref: `/`,
  heading: podcast?.title ?? '',
  subHeading: podcast?.speakers
    ?.map(
      (author) =>
        [author?.firstName, author?.lastName]?.filter(Boolean)?.join(' ') ?? ''
    )
    ?.join(', '),
  description: podcast?.description ?? '',
  collections: podcast?.collections || [],
  tagData:
    podcast?.tags?.map(({ key, name }) => ({
      id: key,
      name,
      href: `/`
    })) ?? [],
  tagBackground: podcast?.topics?.[0]?.primaryColor ?? 'bg-n-200',
  src: podcast?.imageThumbnail?.url ?? '',
  blurhash: podcast?.imageThumbnail?.blurhash,
  href: `/`
});

export const getFeatureArticleData = (article) => ({
  id: article?.id,
  topicName: article?.topics?.[0]?.name ?? '',
  topicHref: `/`,
  prayerCount: article?.prayerCount,
  title: article?.title ?? '',
  publishedAt: article?.publishedAt,
  author:
    article?.authors?.length > 0
      ? map(article?.authors, (item) => {
          return {
            ...item,
            name:
              [item?.firstName, item?.lastName].filter(Boolean).join(' ') ?? '',
            slug: item?.slug
          };
        })
      : [],
  description: article?.description ?? '',
  collections: article?.collections || [],
  tagData:
    article?.tags?.map(({ key, name }) => ({
      id: key,
      name,
      href: `/`
    })) ?? [],
  topicBackground: article?.topics?.[0]?.primaryColor ?? 'bg-n-200',
  src: article?.imageThumbnail?.url ?? '',
  blurhash: article?.imageThumbnail?.blurhash,
  href: `/`,
  commentCount: article?.commentCount,
  html: article?.html
});

export const getFeaturedFormData = (form) => ({
  id: form?.id,
  title: form?.title,
  description: form?.description,
  href: `/`,
  prayerCount: form?.prayerCount,
  cta: form?.ctas ?? null,
  fields: form?.fields
    ? form?.fields?.sort(function (a, b) {
        return a?.order - b?.order;
      })
    : null,
  submitCount: form?.submitCount,
  type: form?.type
});

export const normalizeNegativeNumber = (value) => {
  if (!value) {
    return null;
  }
  const newValue = value;
  return +newValue < 0 ? 0 : newValue;
};

export const timeToString = (date) => {
  const finalDate = `${dayjs(date)?.format('YYYY-MM-DDTHH:mm:ss')}`;
  return finalDate;
};
