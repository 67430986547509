import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Form,
  Input,
  Space,
  Typography
} from 'antd';
import React, { useEffect, useMemo } from 'react';
import {
  AUTO_GENERATED_SORT_BY_TYPES,
  MODULE_TYPES,
  PAGE_TYPES,
  STATIC_DATA_KEYS,
  STATUS_TYPES
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import useStaticData from '../../../../../hooks/useStaticData';
import { Select } from '../../../../videos/components/FormInputs';
import { GET_TOPICS } from '../../../../videos/graphql/Queries';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions, ShowFields, Switch } from './FormInputs';

const getInitialValues = (type) => ({
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  isDefaultModule: false,
  permissions: [],
  settings: {
    title: true,
    description: true,
    viewAll: true,
    topicTitle: true
  },
  config: {
    autoGenerate: false,
    autoGenerateSortBy: null,
    topics: type === MODULE_TYPES.FEATURED_TOPIC ? null : [],
    autoGenerateLimit: null
  }
});

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [
      MODULE_TYPES.TOPIC_CAROUSEL,
      MODULE_TYPES.TOPIC_GRID,
      MODULE_TYPES.TOPIC_LIST
    ]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [MODULE_TYPES.TOPIC_CAROUSEL, MODULE_TYPES.TOPIC_GRID]
  },
  {
    name: 'viewAll',
    label: 'View All Button',
    allowedTypes: [
      MODULE_TYPES.TOPIC_CAROUSEL,
      MODULE_TYPES.TOPIC_LIST,
      MODULE_TYPES.TOPIC_GRID
    ]
  },
  {
    name: 'topicTitle',
    label: 'Topic Title',
    allowedTypes: [
      MODULE_TYPES.TOPIC_CAROUSEL,
      MODULE_TYPES.TOPIC_LIST,
      MODULE_TYPES.TOPIC_GRID,
      MODULE_TYPES.FEATURED_TOPIC
    ]
  }
];

const AUTO_GENERATED_SORT_BY_OPTIONS = [
  { label: 'Recent', value: AUTO_GENERATED_SORT_BY_TYPES.RECENT },
  { label: 'Random', value: AUTO_GENERATED_SORT_BY_TYPES.RANDOM }
];

const MODULE_KEYS = {
  [MODULE_TYPES.TOPIC_CAROUSEL]: 'topicCarouselModule',
  [MODULE_TYPES.TOPIC_LIST]: 'topicListModule',
  [MODULE_TYPES.TOPIC_GRID]: 'topicGridModule',
  [MODULE_TYPES.FEATURED_TOPIC]: 'featuredTopicModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.TOPIC_CAROUSEL]: 'Topic Carousel Configs',
  [MODULE_TYPES.TOPIC_LIST]: 'Topic List Configs',
  [MODULE_TYPES.TOPIC_GRID]: 'Topic Grid Configs',
  [MODULE_TYPES.FEATURED_TOPIC]: 'Feature Topic Configs'
};

const { TOPIC } = PAGE_TYPES;
const ALLOWED_TEMPLATE_CONFIGS = {
  [MODULE_TYPES.FEATURED_TOPIC]: [TOPIC]
};

const TopicForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  isDefaultPage,
  pageType
}) => {
  const [form] = Form.useForm();
  const { data: configData } = useStaticData(STATIC_DATA_KEYS.CONFIGS);
  const isEdit = formType === FORM_TYPES.EDIT;
  const initialValues = useMemo(() => getInitialValues(type), [type]);
  const isMultiTopic = type !== MODULE_TYPES.FEATURED_TOPIC;
  const allowedTemplateConfig =
    isDefaultPage && ALLOWED_TEMPLATE_CONFIGS[type]?.includes(pageType);

  const configProps = Form.useWatch(['config'], form);
  const isDefaultModule = Form.useWatch(['isDefaultModule'], form);
  const { autoGenerate } = configProps ?? initialValues.config;

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const idm = defaultValues?.isDefaultModule;
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        isDefaultModule: idm,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          autoGenerate:
            defaultValues?.moduleData?.config?.autoGenerate ?? false,
          autoGenerateSortBy:
            defaultValues?.moduleData?.config?.topicAutoGenerateSortBy || null,
          autoGenerateLimit:
            defaultValues?.moduleData?.config?.autoGenerateLimit || null,
          ...(isMultiTopic && {
            topics:
              defaultValues?.moduleData?.config?.topics?.map(
                ({ id, name }) => ({ label: name, value: id })
              ) || []
          }),
          ...(!isMultiTopic && {
            topics: defaultValues?.moduleData?.config?.topic
              ? {
                  name: defaultValues?.moduleData?.config?.topic?.name,
                  value: defaultValues?.moduleData?.config?.topic?.id
                }
              : null
          })
        }
      });
    }
  }, [
    form,
    moduleId,
    formType,
    defaultValues,
    form,
    initialValues,
    isMultiTopic
  ]);

  const handleSubmit = ({
    config,
    settings,
    permissions,
    // eslint-disable-next-line no-shadow
    isDefaultModule,
    ...rest
  }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;
    const isDefault = allowedTemplateConfig && isDefaultModule;

    // eslint-disable-next-line no-shadow
    const { topics, autoGenerate, autoGenerateLimit, ...restConfig } =
      config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          autoGenerate,
          ...restConfig,
          ...(isDefault
            ? {
                topicId: '*'
              }
            : {
                ...(!autoGenerate &&
                  isMultiTopic && {
                    topics:
                      topics.map(({ value }, i) => ({
                        topicId: value,
                        order: i + 1
                      })) ?? []
                  }),
                ...(!autoGenerate &&
                  !isMultiTopic && {
                    topicId: topics?.value ?? ''
                  })
              }),
          ...(autoGenerateLimit && {
            autoGenerateLimit: Number(autoGenerateLimit)
          })
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        <Space className="w-full" direction="vertical">
          {allowedTemplateConfig && pageType === TOPIC && (
            <>
              <Typography.Text>Template Config</Typography.Text>
              <Form.Item
                className="m-0"
                name="isDefaultModule"
                valuePropName="checked"
              >
                <Switch label="Use Template Data" />
              </Form.Item>
            </>
          )}
          {(!allowedTemplateConfig ||
            (allowedTemplateConfig && !isDefaultModule)) && (
            <>
              <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
              <div>
                <Form.Item
                  name={['config', 'autoGenerate']}
                  valuePropName="checked"
                >
                  <Switch
                    label={
                      isMultiTopic
                        ? 'Auto Generated Topics'
                        : 'Auto Generated Topic'
                    }
                  />
                </Form.Item>
                {autoGenerate ? (
                  <>
                    <Form.Item
                      label="Auto Generated Sort By"
                      name={['config', 'autoGenerateSortBy']}
                    >
                      <AntdSelect
                        options={AUTO_GENERATED_SORT_BY_OPTIONS}
                        placeholder="Select sort by"
                      />
                    </Form.Item>
                    {isMultiTopic && (
                      <Form.Item
                        label="No. of Auto generated Topics"
                        name={['config', 'autoGenerateLimit']}
                        rules={[
                          formValidatorRules?.number,
                          formValidatorRules?.maxNumberAllowed(
                            configData?.MAX_AUTO_GENERATE_LIMIT?.value || 20
                          )
                        ]}
                      >
                        <Input placeholder="Enter number" />
                      </Form.Item>
                    )}
                  </>
                ) : (
                  <Form.Item
                    label={isMultiTopic ? 'Select Topics' : 'Select Topic'}
                    name={['config', 'topics']}
                    extra={isMultiTopic ? 'Select items in order you want' : ''}
                  >
                    <Select
                      multiple={isMultiTopic}
                      placeholder={
                        isMultiTopic ? 'Select topics' : 'Select topic'
                      }
                      query={GET_TOPICS}
                      variablesSelector={(filter) => ({ filter })}
                      dataSelector={(data) =>
                        data?.topicsAdmin?.topics?.map(({ id, name }) => ({
                          label: name,
                          value: id
                        })) || []
                      }
                      keys={{
                        data: 'topicsAdmin',
                        records: 'topics',
                        count: 'count'
                      }}
                    />
                  </Form.Item>
                )}
              </div>
            </>
          )}
          <Permissions />
        </Space>
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};
export default TopicForm;
