import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, ConfigProvider, Tag, Typography } from 'antd';
import { find, isEmpty } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { AppContext } from '../AppContext';
import {
  GET_WORKSPACE,
  GET_WORKSPACES,
  GET_WORKSPACES_APPS
} from '../app/components/sidebar/graphql/Queries';
import {
  APP,
  APP_VERSION,
  ROLE_KEYS,
  ROUTES,
  WORKSPACE,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../common/constants';
import useCheckPermission from '../hooks/useCheckPermission';
import { Select } from '../modules/videos/components/FormInputs';
import './styles/workspaceHeader.less';

const { Paragraph } = Typography;

const variablesSelectorWorkspace = ({ limit, skip, search }) => ({
  filter: {
    limit,
    skip,
    search
  }
});

const variablesSelectorApp = ({ limit, skip, search }) => ({
  filter: {
    limit,
    skip,
    search
  }
});

function WorkspaceHeader({ location: { pathname } }) {
  const {
    state: { workspace, app, currentUser: { roles = [] } = null },
    dispatch,
    updateWorkspaceConfig
  } = useContext(AppContext);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [openWorkspace, setOpenWorkspace] = useState(false);
  const [openApp, setOpenApp] = useState(false);
  const history = useHistory();
  // commented now as redirecting to landing dashboard
  // const location = useLocation();
  // const { redirectUser } = useRedirectUser();
  const refWorkspace = useRef();
  const refApp = useRef();

  const isAppAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.APP_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isAppEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.APP_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const [getWorkspace, { loading }] = useLazyQuery(GET_WORKSPACE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const isWorkspaceActive = res?.workspace?.isActive;
      if (!isWorkspaceActive && roles?.[0] !== ROLE_KEYS.SUPER_ADMIN) {
        dispatch({
          type: 'SET_WORKSPACE',
          data: null
        });
      } else {
        dispatch({
          type: 'SET_WORKSPACE',
          data: {
            id: res?.workspace?.id,
            label: res?.workspace?.name,
            value: res?.workspace?.uuid
          }
        });
        const permissions = res?.workspace?.permissions || [];
        dispatch({
          type: 'SET_USER_PERMISSIONS',
          data: permissions
        });
        updateWorkspaceConfig(res?.workspace?.config);
      }
    },
    onError: () => {}
  });

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const getId = JSON.parse(localStorage.getItem(WORKSPACE))?.id;
    if (getId)
      getWorkspace({
        variables: {
          where: {
            id: getId
          }
        }
      });
  }, []);

  useEffect(() => {
    dispatch({
      type: 'SET_WORKSPACE_APPS_LOADING',
      data: isAppAllowed
    });
  }, [isAppAllowed]);

  useEffect(() => {
    if (workspace && refApp.current?.refetch) {
      refApp.current?.refetch();
    }
  }, [workspace]);

  const handleWorkspaceChange = (_, { config, ...newValue }) => {
    // eslint-disable-next-line no-undef
    localStorage?.removeItem(APP);
    const permissions =
      find(workspaceData, (item) => item?.uuid === newValue?.value)
        ?.permissions || [];
    dispatch({
      type: 'SET_WORKSPACE',
      data: newValue
    });
    dispatch({
      type: 'SET_USER_PERMISSIONS',
      data: permissions
    });
    updateWorkspaceConfig(config);
    history.push(ROUTES.DASHBOARD_LANDING);
    // commented now as redirecting to landing dashboard
    // const getRoute = redirectUser(permissions);
    // if (getRoute === location.pathname) {
    //   // eslint-disable-next-line no-undef
    //   window.location.reload();
    // } else {
    //   history.push(getRoute);
    // }
  };

  const handleAppChange = (_, newValue) => {
    dispatch({ type: 'SET_APP', data: newValue });
    const pathNameArray = pathname?.split('/');
    if (pathNameArray?.length > 2 || pathNameArray?.[1] === 'apps') {
      history.push(ROUTES.DASHBOARD_LANDING);
    } else {
      // eslint-disable-next-line no-undef
      window.location.reload();
    }
  };

  return (
    <div className="workspace-header d-flex justify-between align-center">
      <ConfigProvider
        theme={{
          components: {
            Select: {
              colorText: '#000000',
              colorTextPlaceholder: '#000000',
              colorTextQuaternary: '#000000'
            }
          }
        }}
      >
        <div>
          <Select
            onClick={() => setOpenWorkspace(!openWorkspace)}
            onBlur={() => setOpenWorkspace(false)}
            onFocus={() => setOpenWorkspace(true)}
            ref={refWorkspace}
            open={openWorkspace}
            className="max-w-50 header-select"
            labelRender={(option) => {
              return (
                <div className="d-flex align-center w-full">
                  <span className="text-ellipses">{option?.label}</span>
                  <div className="d-flex align-center ml-8">
                    <Paragraph
                      className="copy-icon black-color mr-4"
                      copyable={{
                        onCopy: (e) => {
                          e.stopPropagation();
                          setOpenWorkspace(false);
                        },
                        text: workspace?.value,
                        tooltips: 'Copy Workspace UUID'
                      }}
                    />
                    {[ROLE_KEYS.ADMIN, ROLE_KEYS.SUPER_ADMIN]?.includes(
                      roles?.[0]
                    ) && (
                      <Button
                        type="link"
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenWorkspace(false);
                          history?.push(
                            `${ROUTES?.WORKSPACES}/${workspace?.id}/edit`
                          );
                        }}
                        icon={<EditOutlined />}
                      />
                    )}
                  </div>
                </div>
              );
            }}
            popupMatchSelectWidth={false}
            variablesSelector={variablesSelectorWorkspace}
            value={workspace}
            onChange={handleWorkspaceChange}
            placeholder="Workspace"
            query={GET_WORKSPACES}
            variant="borderless" // min antd should be 5.13.0
            dropdownRender={(menu) => {
              return (
                <div
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  {[ROLE_KEYS.ADMIN, ROLE_KEYS.SUPER_ADMIN]?.includes(
                    roles?.[0]
                  ) && (
                    <Button
                      className="select-add-btn"
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenWorkspace(false);
                        history?.push(`${ROUTES?.WORKSPACES}/add`);
                      }}
                    >
                      Add Workspace
                    </Button>
                  )}
                  <div className="header-dropdown">{menu}</div>
                </div>
              );
            }}
            optionRender={(option) => (
              <div className="d-flex justify-between align-center w-full">
                <span className="mr-16">{option?.label}</span>
                <div className="d-flex align-center">
                  <Paragraph
                    className="copy-icon mr-4"
                    copyable={{
                      onCopy: (e) => {
                        e.stopPropagation();
                        setOpenWorkspace(true);
                      },
                      text: option?.value,
                      tooltips: 'Copy Workspace UUID'
                    }}
                  />
                  {[ROLE_KEYS.ADMIN, ROLE_KEYS.SUPER_ADMIN]?.includes(
                    roles?.[0]
                  ) && (
                    <Button
                      className="white-btn"
                      type="text"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenWorkspace(false);
                        history?.push(
                          `${ROUTES?.WORKSPACES}/${option?.data?.id}/edit`
                        );
                      }}
                      icon={<EditOutlined />}
                    />
                  )}
                </div>
              </div>
            )}
            dataSelector={(data) =>
              data?.workspaces?.workspaces?.map(
                ({ id, uuid, name, config }) => ({
                  id,
                  label: name,
                  value: uuid,
                  config
                })
              ) || []
            }
            keys={{
              data: 'workspaces',
              records: 'workspaces',
              count: 'count'
            }}
            queryOptions={{
              skip: loading,
              onCompleted: ({ workspaces }) => {
                if (
                  isEmpty(workspaces?.workspaces) &&
                  roles?.[0] !== ROLE_KEYS?.SUPER_ADMIN
                ) {
                  history.push(ROUTES.LOGOUT);
                }
                const firstWorkspace = workspaces?.workspaces?.[0];
                const selectedWorkspace =
                  // eslint-disable-next-line no-undef
                  JSON?.parse(localStorage.getItem(WORKSPACE)) ||
                  (firstWorkspace
                    ? {
                        id: firstWorkspace?.id,
                        label: firstWorkspace?.name,
                        value: firstWorkspace?.uuid
                      }
                    : null);
                const permissions =
                  find(
                    workspaces?.workspaces,
                    (item) =>
                      item?.id === selectedWorkspace?.id ||
                      item?.uuid === selectedWorkspace?.value
                  )?.permissions || [];
                // eslint-disable-next-line no-undef
                if (!JSON?.parse(localStorage.getItem(WORKSPACE))) {
                  dispatch({
                    type: 'SET_USER_PERMISSIONS',
                    data: permissions
                  });
                }
                dispatch({
                  type: 'SET_WORKSPACE',
                  data: selectedWorkspace
                });
                dispatch({ type: 'SET_LOADING', data: false });
                setWorkspaceData(workspaces?.workspaces);
              },
              onError() {
                dispatch({
                  type: 'SET_WORKSPACE_APPS_LOADING',
                  data: false
                });
                dispatch({ type: 'SET_LOADING', data: false });
              }
            }}
          />
          <span className="font-20">/</span>
          {isAppAllowed && (
            <Select
              onClick={() => setOpenApp(!openApp)}
              onBlur={() => setOpenApp(false)}
              onFocus={() => setOpenApp(true)}
              ref={refApp}
              className="max-w-50 header-select"
              open={openApp}
              popupMatchSelectWidth={false}
              variablesSelector={variablesSelectorApp}
              onChange={handleAppChange}
              value={app}
              placeholder="App"
              query={GET_WORKSPACES_APPS}
              variant="borderless" // min antd should be 5.13.0
              labelRender={(option) => {
                return (
                  <div className="d-flex align-center w-full">
                    <span className="text-ellipses">{option?.label}</span>
                    <div className="d-flex align-center ml-8">
                      <Paragraph
                        className="copy-icon black-color mr-4"
                        copyable={{
                          onCopy: (e) => {
                            e.stopPropagation();
                            setOpenApp(false);
                          },
                          text: app?.value,
                          tooltips: 'Copy App UUID'
                        }}
                      />
                      {isAppEditAllowed && (
                        <Button
                          type="link"
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenApp(false);
                            history?.push(`${ROUTES.APPS}/${app?.id}/edit`);
                          }}
                          icon={<EditOutlined />}
                        />
                      )}
                    </div>
                  </div>
                );
              }}
              dropdownRender={(menu) => {
                return (
                  <div
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    {isAppEditAllowed && (
                      <Button
                        className="select-add-btn"
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenApp(false);
                          history?.push(`${ROUTES?.APPS}/add`);
                        }}
                      >
                        Add App
                      </Button>
                    )}
                    <div className="header-dropdown">{menu}</div>
                  </div>
                );
              }}
              optionRender={(option) => (
                <div className="d-flex justify-between align-center w-full">
                  <span className="mr-16">{option?.label}</span>
                  <div className="d-flex align-center">
                    <Paragraph
                      className="copy-icon mr-4"
                      copyable={{
                        onCopy: (e) => {
                          e.stopPropagation();
                          setOpenApp(true);
                        },
                        text: option?.value,
                        tooltips: 'Copy App UUID'
                      }}
                    />
                    {isAppEditAllowed && (
                      <Button
                        className="white-btn"
                        type="text"
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenApp(false);
                          history?.push(
                            `${ROUTES.APPS}/${option?.data?.id}/edit`
                          );
                        }}
                        icon={<EditOutlined />}
                      />
                    )}
                  </div>
                </div>
              )}
              dataSelector={(data) =>
                data?.workspaceApps?.workspaceApps?.map(
                  ({ uuid, name, id }) => ({
                    label: name,
                    value: uuid,
                    id
                  })
                ) || []
              }
              keys={{
                data: 'workspaceApps',
                records: 'workspaceApps',
                count: 'count'
              }}
              queryOptions={{
                skip: !workspace,
                onCompleted: ({ workspaceApps }) => {
                  const firstApp = workspaceApps?.workspaceApps?.[0];
                  const selectedApp =
                    // eslint-disable-next-line no-undef
                    JSON?.parse(localStorage.getItem(APP)) ||
                    (firstApp
                      ? {
                          label: firstApp?.name,
                          value: firstApp?.uuid,
                          id: firstApp?.id
                        }
                      : null);
                  dispatch({ type: 'SET_APP', data: selectedApp });
                  dispatch({
                    type: 'SET_WORKSPACE_APPS_LOADING',
                    data: false
                  });
                },
                onError() {
                  dispatch({
                    type: 'SET_WORKSPACE_APPS_LOADING',
                    data: false
                  });
                }
              }}
            />
          )}
        </div>
      </ConfigProvider>
      <Tag className="app-version">v{APP_VERSION}</Tag>
    </div>
  );
}
export default withRouter(WorkspaceHeader);
