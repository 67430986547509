import { Button, Select, Space, Tag, Tooltip } from 'antd';
import Typography from 'antd/es/typography/Typography';
import moment from 'moment';
import { ArrowClockwise, FileArrowDown } from 'phosphor-react';
import React, { useMemo, useRef, useState } from 'react';
import {
  EXPORT_JOB_TYPES,
  FULL_DATE_TIME_FORMAT,
  LIST_TYPES,
  MODULES,
  STATUS_COLORS
} from '../../common/constants';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import { GET_EXPORT_JOBS } from './graphql/Queries';

const variablesSelector = ({ limit, offset, type }) => ({
  filter: {
    limit,
    skip: offset,
    type
  }
});

const dataSelector = ({ exportJobs }) => ({
  data: exportJobs?.data || [],
  count: exportJobs?.count || 0
});

const EXPORT_JOB_OPTIONS = [
  { label: 'User', value: EXPORT_JOB_TYPES?.USER },
  { label: 'Admins', value: EXPORT_JOB_TYPES?.ADMIN_USER }
];

const Action = ({ url }) => {
  return (
    <Tooltip title="Download">
      <a href={url} download type="text" className="text-btn">
        <FileArrowDown size={22} />
      </a>
    </Tooltip>
  );
};

const Filter = ({ filter }) => {
  const { search, legacyUsers, migratedAccount } = filter ?? {};

  return (
    <Space size={[0, 8]} wrap>
      {search && (
        <Tag className="custom-tag" color={STATUS_COLORS.PENDING}>
          {search}
        </Tag>
      )}
      {legacyUsers && (
        <Tag className="custom-tag" color={STATUS_COLORS.FAILED}>
          Legacy Users
        </Tag>
      )}
      {migratedAccount && (
        <Tag className="custom-tag" color={STATUS_COLORS.SUCCESS}>
          Migrated Account
        </Tag>
      )}
    </Space>
  );
};

const columns = [
  {
    title: 'Job ID',
    dataIndex: 'id',
    key: 'id',
    width: 380
  },
  {
    title: 'Job Filter',
    dataIndex: 'filter',
    render: (_, { filter }) => <Filter filter={{ ...filter }} />
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (value) => (
      <Tag className="m-0 custom-tag" color={STATUS_COLORS[value]}>
        {value}
      </Tag>
    )
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    render: (value) =>
      value ? moment(value).format(FULL_DATE_TIME_FORMAT) : ''
  },
  {
    title: 'Action',
    dataIndex: 'fileUrl',
    render: (value) => <Action url={value} />
  }
];

const ExportJobs = () => {
  const ref = useRef();
  const [type, setType] = useState(EXPORT_JOB_TYPES.USER);

  const filters = useMemo(
    () => ({
      type
    }),
    [type]
  );
  const handleTypeChange = (value) => setType(value);

  const handleRefresh = () => {
    if (ref?.current?.refresh) {
      ref?.current?.refresh();
    }
  };

  return (
    <>
      <PageHeader menu={MODULES?.REPORTS} />
      <div className="assets-filter-wrapper">
        <div className="asset-filter-left d-flex align-center">
          <Typography>
            <strong>Note:</strong> The available downloadable links are valid
            for one day only from the date it was created. It won't be available
            afterward. You have to filter and re-export again.
          </Typography>
        </div>
        <div className="asset-filter-right">
          <div className="asset-filter">
            <Select
              value={type}
              popupMatchSelectWidth={false}
              placeholder="Select type"
              options={EXPORT_JOB_OPTIONS}
              onChange={handleTypeChange}
              className="mr-8"
            />
            <Tooltip title="Refresh" placement="left">
              <Button
                className="text-btn d-flex align-center justify-center"
                type="text"
                icon={<ArrowClockwise size={24} />}
                onClick={handleRefresh}
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <PageList
        ref={ref}
        filters={filters}
        listMode={LIST_TYPES.TABLE}
        query={GET_EXPORT_JOBS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{ columns }}
        limit={30}
      />
    </>
  );
};

export default ExportJobs;
