import { gql } from '@apollo/client';

export const CREATE_CONTRIBUTOR = gql`
  mutation createContributor($data: CreateContributorInput!) {
    createContributor(data: $data) {
      message
      contributor {
        id
        firstName
        lastName
        displayName
        image {
          id
          url
        }
        thumbnail {
          id
          url
        }
        primaryColor
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_CONTRIBUTOR = gql`
  mutation updateContributor(
    $where: ContributorWhereUniqueInput!
    $data: UpdateContributorInput!
  ) {
    updateContributor(where: $where, data: $data) {
      message
      contributor {
        id
        firstName
        lastName
        displayName
        image {
          id
          url
        }
        thumbnail {
          id
          url
        }
        primaryColor
        createdAt
        updatedAt
      }
    }
  }
`;
