import { EditOutlined, EyeFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Select as AntdSelect, Button, Tooltip } from 'antd';
import { find } from 'lodash';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  LIST_TYPES,
  MODULES,
  PROVIDER_TYPES,
  PROVIDER_TYPES_OPTIONS,
  ROUTES,
  VIDEO_PLATFORMS_SORT_FIELD,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { modalContext } from '../../components/AppComponentContainer';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import { Switch } from '../pages/component/pageModules/moduleForms/FormInputs';
import { UPDATE_WORKSPACE_PLATFORM } from './graphql/Mutations';
import { GET_PLATFORMS } from './graphql/Queries';

export const PROVIDER_TYPE_QUERY_KEY = 'provider-type';

const showDefaultSwitch = [
  PROVIDER_TYPES.STORAGE,
  PROVIDER_TYPES.VIDEO,
  PROVIDER_TYPES.AUDIO,
  PROVIDER_TYPES.PAYMENT,
  PROVIDER_TYPES.EMAIL,
  PROVIDER_TYPES.PUSH_NOTIFICATION,
  PROVIDER_TYPES.SMS_OTP,
  PROVIDER_TYPES.SMS,
  PROVIDER_TYPES.USER_AUDIO,
  PROVIDER_TYPES.USER_VIDEO
];

export const getProviderType = (type) =>
  Object.values(PROVIDER_TYPES).includes(type)
    ? type
    : PROVIDER_TYPES.AUTHENTICATION;

const variablesSelector = ({
  offset,
  sortField,
  sortOrder,
  search,
  providerType,
  limit
}) => ({
  filter: {
    skip: offset,
    search,
    type: providerType,
    limit
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = (data) => ({
  data: data?.workspacePlatforms?.workspacePlatforms ?? [],
  count: data?.workspacePlatforms?.count ?? 0
});

const Action = (record) => {
  const { id, type, typeLabel, isEditable } = record;

  const history = useHistory();

  const handleEdit = () => {
    if (!isEditable) return;
    const newParams = new URLSearchParams();
    newParams.set(PROVIDER_TYPE_QUERY_KEY, type);
    history.push({
      pathname: `${ROUTES?.PLATFORMS}/${id}/edit`,
      search: newParams.toString()
    });
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.PLATFORM_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  return (
    <div className="d-flex align-center">
      <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} ${typeLabel} Platform`}>
        <Button
          type="text"
          disabled={!isEditable}
          className="text-btn"
          onClick={handleEdit}
        >
          {isViewOnly ? <EyeFilled /> : <EditOutlined />}
        </Button>
      </Tooltip>
    </div>
  );
};

const columns = ({
  refresh,
  isAddEditAllowed,
  handleChangeSwitch,
  typeLabel,
  providerType,
  isViewOnly
}) => [
  {
    title: 'Name',
    dataIndex: 'name',
    ellipsis: true
  },
  {
    title: 'Provider',
    dataIndex: 'provider',
    ellipsis: true,
    render: (provider) => provider?.name
  },
  {
    title: 'Description',
    dataIndex: 'description',
    ellipsis: true
  },
  ...(showDefaultSwitch?.includes(providerType)
    ? [
        {
          title: 'Default',
          dataIndex: 'isDefault',
          ellipsis: true,
          render: (isDefault, record) => {
            return (
              <span onClick={(e) => e.stopPropagation()}>
                <Tooltip title={`Default ${typeLabel} Platform`}>
                  <Switch
                    disabled={isViewOnly}
                    onChange={() => {
                      handleChangeSwitch({
                        record,
                        switchType: 'isDefault',
                        refresh
                      });
                    }}
                    value={isDefault}
                  />
                </Tooltip>
              </span>
            );
          }
        }
      ]
    : []),
  {
    title: 'Active',
    dataIndex: 'isActive',
    ellipsis: true,
    render: (isActive, record) => {
      return (
        <span onClick={(e) => e.stopPropagation()}>
          <Tooltip
            title={`${isActive ? 'Inactive' : 'Active'} ${typeLabel} Platform`}
          >
            <Switch
              className="mx-8"
              onChange={() => {
                handleChangeSwitch({ record, switchType: 'isActive', refresh });
              }}
              value={isActive}
              disabled={isViewOnly || record?.isDefault}
            />
          </Tooltip>
        </span>
      );
    }
  },
  {
    title: 'Action',
    dataIndex: 'id',
    render: (_, record) => (
      <Action
        {...record}
        isAddEditAllowed={isAddEditAllowed}
        typeLabel={typeLabel}
      />
    )
  }
];

function Platforms({ history, location }) {
  const { search, pathname } = location;
  const [updateWorkspacePlatform] = useMutation(UPDATE_WORKSPACE_PLATFORM);

  const handleChangeSwitch = async ({
    record,
    refresh,
    switchType = 'isActive'
  }) => {
    modalContext?.confirm({
      title: `Are you sure, you want to ${
        switchType === 'isActive'
          ? `${record?.isActive ? 'Inactive' : 'Active'}`
          : 'Change Default'
      } platform?`,
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      onOk() {
        updateWorkspacePlatform({
          variables: {
            where: { id: record?.id, type: record?.type },
            data: {
              ...(switchType === 'isActive' && { isActive: !record?.isActive }),
              ...(switchType === 'isDefault' && {
                isDefault: !record?.isDefault
              })
            }
          }
        })
          .then(({ errors }) => {
            if (refresh && !errors) {
              refresh();
            }
          })
          .catch();
      }
    });
  };

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.PLATFORM_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.PLATFORM_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const providerType = useMemo(() => {
    const params = new URLSearchParams(search);
    return getProviderType(params.get(PROVIDER_TYPE_QUERY_KEY));
  }, [search]);

  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: VIDEO_PLATFORMS_SORT_FIELD,
      field: VIDEO_PLATFORMS_SORT_FIELD[0].value
    }
  });

  const handleShowModal = () => {
    const newParams = new URLSearchParams(search);
    newParams.set(PROVIDER_TYPE_QUERY_KEY, providerType);
    history.push({
      pathname: `${ROUTES?.PLATFORMS}/add`,
      search: newParams.toString()
    });
  };

  const handleProviderTypeChange = (value) => {
    const newParams = new URLSearchParams(search);
    newParams.set(PROVIDER_TYPE_QUERY_KEY, value);
    history.replace({
      pathname,
      search: newParams.toString()
    });
  };

  const filters = useMemo(() => ({ ...filterProps.filters, providerType }), [
    filterProps.filters,
    providerType
  ]);

  const typeLabel = find(
    PROVIDER_TYPES_OPTIONS,
    (item) => item?.value === providerType
  )?.label;

  return (
    <>
      <PageHeader
        menu={MODULES?.WORKSPACE_SETTINGS}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed}
      />
      <FilterBar
        {...filterProps}
        show={{
          listModes: false
        }}
        SearchProps={{ placeholder: 'Search Platforms' }}
      >
        <AntdSelect
          popupMatchSelectWidth={false}
          options={PROVIDER_TYPES_OPTIONS}
          value={providerType}
          onChange={handleProviderTypeChange}
        />
      </FilterBar>
      <PageList
        filters={filters}
        listMode={LIST_TYPES.TABLE}
        query={GET_PLATFORMS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{
          columns: (props) =>
            columns({
              ...props,
              isAddEditAllowed,
              handleChangeSwitch,
              typeLabel,
              providerType,
              isViewOnly
            })?.filter((item) => item !== false)
        }}
        limit={20}
      />
    </>
  );
}

export default Platforms;
