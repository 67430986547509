import {
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Form,
  Row,
  Select,
  TimePicker,
  Tooltip
} from 'antd';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useMemo, useState } from 'react';
import { Switch } from '../../pages/component/pageModules/moduleForms/FormInputs';

const MODES = {
  DATE: 'DATE',
  RECURRING: 'RECURRING'
};

const DAYS = {
  SUNDAY: 'SUNDAY',
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY'
};

const TIMEZONE_OPTIONS = moment.tz.names().map((timezone) => ({
  label: `${moment.tz(timezone).format('([GMT]Z)')} ${timezone}`,
  value: timezone
}));

const DAYS_OPTIONS = Object.values(DAYS).map((day) => ({
  label: day,
  value: day
}));

export const getInitialScheduleState = () => ({
  schedule: {
    isScheduled: false,
    mode: MODES.DATE,
    timeZone: moment.tz.guess(),
    startTime: dayjs().add(5, 'minutes'),
    endTime: dayjs('23:59', 'HH:mm'),
    date: dayjs(),
    days: []
  }
});

export const getServerState = (value) => {
  if (!value || !value?.isScheduled)
    return {
      isScheduled: false,
      schedule: null
    };

  const isFixDay = value?.mode === MODES.DATE;

  return {
    isScheduled: true,
    schedule: {
      mode: value?.mode,
      timeZone: value?.timeZone,
      startTime: value?.startTime?.format('HH:mm'),
      endTime: value?.endTime?.format('HH:mm'),
      date: isFixDay ? value?.date?.format('YYYY-MM-DD') : null,
      days: isFixDay ? null : value?.days
    }
  };
};

export const getFormState = (value) => {
  const { isScheduled, schedule } = value || {};
  if (!value || !isScheduled) return getInitialScheduleState();

  return {
    schedule: {
      isScheduled,
      mode: schedule?.mode || MODES.DATE,
      timeZone: schedule?.timeZone || moment.tz.guess(),
      startTime: dayjs(schedule?.startTime || '00:00', 'HH:mm'),
      endTime: dayjs(schedule?.endTime || '23:59', 'HH:mm'),
      date: dayjs(schedule?.date || new Date()),
      days: schedule?.days || []
    }
  };
};

function ModeInput({ value, onChange, label, mode }) {
  return (
    <Checkbox
      checked={value === mode}
      onChange={(e) => {
        const checked = e?.target?.checked;
        if (checked) onChange(mode);
      }}
    >
      {label}
    </Checkbox>
  );
}

function DaysInput({ value, onChange }) {
  return (
    <Flex gap={8} wrap>
      {DAYS_OPTIONS.map((day) => (
        <Tooltip
          title={`${day.label.charAt(0)}${day.label.slice(1).toLowerCase()}`}
          key={day.value}
        >
          <button
            type="button"
            className={`${
              value?.includes(day.value) ? 'active' : ''
            } day-input`}
            onClick={() => {
              if (value?.includes(day.value)) {
                onChange(value?.filter((d) => d !== day.value));
              } else {
                onChange([...value, day.value]);
              }
            }}
          >
            {day?.label?.charAt(0)}
          </button>
        </Tooltip>
      ))}
    </Flex>
  );
}

function ScheduleContentInput({
  form,
  checkIfDataChanged,
  isEdit,
  fullWidth = false
}) {
  const schedule =
    Form.useWatch('schedule', form) ?? getInitialScheduleState().schedule;

  const { isScheduled, startTime, timeZone, mode, date } = schedule;

  const isChanged = useMemo(() => {
    if (!isEdit) return true;
    return checkIfDataChanged?.(getServerState(schedule)?.schedule);
  }, [schedule, checkIfDataChanged, isEdit]);

  return (
    <>
      <Form.Item
        name={['schedule', 'isScheduled']}
        valuePropName="checked"
        className={isScheduled ? 'mb-8' : ''}
      >
        <Switch label="Schedule" />
      </Form.Item>
      {isScheduled && (
        <>
          <Flex gap={16} className="mb-12">
            <Form.Item name={['schedule', 'mode']} className="mb-0">
              <ModeInput mode={MODES.DATE} label="On fix day" />
            </Form.Item>
            <Form.Item name={['schedule', 'mode']} className="mb-0">
              <ModeInput mode={MODES.RECURRING} label="On recurring days" />
            </Form.Item>
          </Flex>
          {mode === MODES.DATE && (
            <Form.Item
              name={['schedule', 'date']}
              label="Select Date"
              rules={[
                {
                  async validator(_, value) {
                    if (!isChanged) return Promise.resolve();

                    if (dayjs().startOf('day').isAfter(value)) {
                      return Promise.reject(
                        new Error('Please select a valid date')
                      );
                    }

                    return Promise.resolve();
                  }
                }
              ]}
            >
              <DatePicker
                allowClear={false}
                disabledDate={(day) => dayjs().startOf('day').isAfter(day)}
                onChange={() => {
                  form?.validateFields([
                    ['schedule', 'startTime'],
                    ['schedule', 'endTime']
                  ]);
                }}
              />
            </Form.Item>
          )}
          {mode === MODES.RECURRING && (
            <Form.Item
              name={['schedule', 'days']}
              label="Select days"
              required
              rules={[
                {
                  validator(_, value) {
                    if (value?.length <= 0) {
                      if (!isChanged) return Promise.resolve();

                      throw new Error('Please select at least one day!');
                    }
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <DaysInput
                onChange={() => {
                  form?.validateFields([
                    ['schedule', 'startTime'],
                    ['schedule', 'endTime']
                  ]);
                }}
              />
            </Form.Item>
          )}
          <Form.Item name={['schedule', 'timeZone']} label="Timezone">
            <Select
              showSearch
              options={TIMEZONE_OPTIONS}
              placeholder="Select timezone"
              popupMatchSelectWidth={false}
              onChange={() => {
                form?.validateFields([['schedule', 'startTime']]);
              }}
            />
          </Form.Item>
          <Row gutter={8}>
            <Col sm={fullWidth ? 12 : 10}>
              <Form.Item
                name={['schedule', 'startTime']}
                label="Start time"
                rules={[
                  {
                    async validator(_, value) {
                      if (!isChanged || mode === MODES.RECURRING) {
                        return Promise.resolve();
                      }

                      if (
                        mode === MODES.DATE &&
                        dayjs().endOf('day').isBefore(date)
                      ) {
                        return Promise.resolve();
                      }

                      const currentTimeWithTimezone = moment().tz(timeZone);
                      const startTimeWithTimezone = moment(
                        value?.toString()
                      ).tz(timeZone, true);

                      if (
                        !dayjs(startTimeWithTimezone.toString()).isAfter(
                          currentTimeWithTimezone.toString()
                        )
                      ) {
                        return Promise.reject(
                          new Error(
                            `Start time must be > ${currentTimeWithTimezone.format(
                              'hh:mm a (z)'
                            )}`
                          )
                        );
                      }

                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <TimePicker
                  showSecond={false}
                  format="hh:mm a"
                  allowClear={false}
                  onChange={() => {
                    form?.validateFields([['schedule', 'endTime']]);
                  }}
                />
              </Form.Item>
            </Col>
            <Col sm={fullWidth ? 12 : 10}>
              <Form.Item
                name={['schedule', 'endTime']}
                label="End time"
                rules={[
                  {
                    async validator(_, value) {
                      if (!isChanged) return Promise.resolve();

                      if (!dayjs(value).isAfter(startTime)) {
                        return Promise.reject(
                          new Error(
                            `End time must be > ${dayjs(startTime).format(
                              'hh:mm a'
                            )}`
                          )
                        );
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <TimePicker
                  showSecond={false}
                  format="hh:mm a"
                  allowClear={false}
                  onChange={() => {
                    form?.validateFields([['schedule', 'startTime']]);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export const useScheduleContentChange = () => {
  const [data, setData] = useState(null);

  const setInitialData = useCallback((schedule) => {
    if (!schedule) return;

    setData({
      startTime: schedule?.startTime,
      endTime: schedule?.endTime,
      date: schedule?.date,
      days: schedule?.days,
      timeZone: schedule?.timeZone
    });
  }, []);

  const checkIfDataChanged = useCallback(
    (schedule) => {
      if (!schedule) return false;

      const createDaysSortedArray = (items) => [...(items ?? [])].sort();

      const isSameStartTime = data?.startTime === schedule?.startTime;
      const isSameEndTime = data?.endTime === schedule?.endTime;
      const isSameDate = data?.date === schedule?.date;
      const isSameTimeZone = data?.timeZone === schedule?.timeZone;
      const isSameDays = isEqual(
        createDaysSortedArray(data?.days),
        createDaysSortedArray(schedule?.days)
      );

      return [
        isSameStartTime,
        isSameEndTime,
        isSameDate,
        isSameTimeZone,
        isSameDays
      ].includes(false);
    },
    [data]
  );

  return { setInitialData, checkIfDataChanged };
};

export default ScheduleContentInput;
