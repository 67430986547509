import { EditOutlined, EyeFilled } from '@ant-design/icons';
import { Select as AntdSelect, Button, Tooltip } from 'antd';
import { capitalize } from 'lodash';
import { UsersThree } from 'phosphor-react';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import videoPlaceholder from '../../assets/images/imagePlaceholder.png';
import {
  ASSET_TYPE,
  COMMUNITY_VISIBILITY_TYPES,
  LIST_TYPES,
  MODULES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import Image from '../../components/Image';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import { Select } from '../videos/components/FormInputs';
import { GET_TAGS, GET_TOPICS } from '../videos/graphql/Queries';
import ManageMembersModal from './components/ManageMembersModal';
import { GET_COMMUNITIES } from './graphql/Queries';

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder,
  tagIds,
  topicIds,
  visibility
}) => ({
  filter: {
    skip: offset,
    search,
    limit,
    tagIds,
    topicIds,
    visibility
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ communitiesAdmin }) => ({
  data: communitiesAdmin?.communities ?? [],
  count: communitiesAdmin?.count ?? 0
});

function Action({ id, updateData, isViewOnly, isAddEditAllowed }) {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const handleEdit = () => {
    history.push(`${ROUTES?.COMMUNITY_MODULE}/${id}/edit`);
  };

  const handleManageMember = () => {
    setOpen(true);
  };

  return (
    <>
      {open && (
        <ManageMembersModal
          open={open}
          setOpen={setOpen}
          communityId={id}
          isAddEditAllowed={isAddEditAllowed}
          isViewOnly={isViewOnly}
          updateData={updateData}
        />
      )}
      <div className="d-flex align-center">
        {isAddEditAllowed && (
          <Tooltip title="Manage Members">
            <Button
              type="text"
              className="text-btn"
              onClick={handleManageMember}
            >
              <UsersThree width={24} height={24} />
            </Button>
          </Tooltip>
        )}
        <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} Community`}>
          <Button type="text" className="text-btn" onClick={handleEdit}>
            {isViewOnly ? <EyeFilled /> : <EditOutlined />}
          </Button>
        </Tooltip>
      </div>
    </>
  );
}

export default function Communities() {
  const [topics, setTopics] = useState([]);
  const [tags, setTags] = useState([]);
  const [type, setType] = useState(null);
  const filterProps = useFilterBar();
  const history = useHistory();

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isAssetAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const columns = useMemo(
    () => ({ showPreview, updateData }) => [
      {
        title: 'Image',
        dataIndex: 'imageUrl',
        render: (value, { title }) => (
          <Image
            className="group-img-table pointer"
            blurHash={value?.blurhash}
            src={value ?? videoPlaceholder}
            alt={title}
            onClick={() => {
              showPreview({
                type: ASSET_TYPE.IMAGE,
                url: value ?? videoPlaceholder
              });
            }}
          />
        ),
        width: 110
      },
      {
        title: 'Theme',
        dataIndex: 'primaryColor',
        render: (value) => {
          return value ? (
            <div
              className="color-list mr-0"
              style={{
                // Used inline style as its dynamic value
                backgroundColor: value
              }}
            />
          ) : (
            '-'
          );
        },
        width: 110
      },
      {
        title: 'Type',
        dataIndex: 'visibility',
        ellipsis: true,
        render: (visibility) => capitalize(visibility),
        width: 110
      },
      {
        title: 'Title',
        dataIndex: 'title',
        ellipsis: true
      },
      {
        title: 'Description',
        dataIndex: 'description',
        ellipsis: true
      },
      {
        title: 'Members',
        dataIndex: 'membersCount',
        width: 100
      },
      ...(isAssetAllowed
        ? [
            {
              title: 'Action',
              dataIndex: 'id',
              render: (_, record) => (
                <Action
                  {...record}
                  isAddEditAllowed={isAddEditAllowed}
                  isViewOnly={isViewOnly}
                  updateData={updateData}
                />
              ),
              width: 150
            }
          ]
        : [])
    ],
    [isAssetAllowed, isViewOnly, isAddEditAllowed]
  );

  const handleShowModal = () => {
    history.push(`${ROUTES?.COMMUNITY_MODULE}/add`);
  };

  const filters = useMemo(
    () => ({
      ...filterProps.filters,
      topicIds: topics,
      tagIds: tags,
      visibility: type
    }),
    [filterProps.filters, topics, tags, type]
  );

  return (
    <>
      <PageHeader
        menu={MODULES?.CONTENT_UNITS}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed && isAssetAllowed}
      />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Communities' }}
        show={{
          listModes: false
        }}
      >
        <Select
          mode="multiple"
          placeholder="Topics"
          query={GET_TOPICS}
          popupMatchSelectWidth={false}
          variablesSelector={(filter) => ({ filter })}
          dataSelector={(data) =>
            data?.topicsAdmin?.topics?.map(({ id, name }) => ({
              label: name,
              value: id
            })) ?? 0
          }
          keys={{
            data: 'topicsAdmin',
            records: 'topics',
            count: 'count'
          }}
          labelInValue={false}
          value={topics}
          onChange={(value) => setTopics(value)}
          className="minimum-w-dropdown"
        />
        <Select
          mode="multiple"
          placeholder="Tags"
          query={GET_TAGS}
          variablesSelector={(filter) => ({ filter })}
          dataSelector={(data) =>
            data?.tagsAdmin?.tags?.map(({ id, name }) => ({
              label: name,
              value: id
            })) ?? []
          }
          keys={{
            data: 'tagsAdmin',
            records: 'tags',
            count: 'count'
          }}
          labelInValue={false}
          value={tags}
          onChange={(value) => setTags(value)}
          className="minimum-w-dropdown"
        />
        <AntdSelect
          options={COMMUNITY_VISIBILITY_TYPES.map(({ label, value }) => ({
            label,
            value
          }))}
          placeholder="Type"
          popupMatchSelectWidth={false}
          allowClear
          labelInValue={false}
          value={type}
          onChange={(value) => setType(value)}
        />
      </FilterBar>

      <PageList
        filters={filters}
        listMode={LIST_TYPES.TABLE}
        query={GET_COMMUNITIES}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{ columns }}
      />
    </>
  );
}
