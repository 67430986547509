import React from 'react';
import { useParams } from 'react-router-dom';
import { MODULE_TYPES } from '../../../../common/constants';
import FormLayout from '../../../component/layout/FormLayout';
import { FORM_TYPES, useEditPage } from '../../context/EditPageProvider';
import { usePagePreview } from '../../context/PreviewPageProvider';
import AddModule from './AddModule';
import { PlaceHolderComponents } from './LeftPanel';
import ArticleForm from './moduleForms/ArticleForm';
import CTAForm from './moduleForms/CTAForm';
import CTAGridCarouselForm from './moduleForms/CTAGridCarouselForm';
import CollectionForm from './moduleForms/CollectionForm';
import CollectionItemForm from './moduleForms/CollectionItemForm';
import CommunityForm from './moduleForms/CommunityForm';
import ContributorForm from './moduleForms/ContributorForm';
import CopyHeavyForm from './moduleForms/CopyHeavyForm';
import DonationForm from './moduleForms/DonationForm';
import FAQForm from './moduleForms/FAQForm';
import FormModule from './moduleForms/FormModule';
import InformationForm from './moduleForms/InformationForm';
import LeadGenForm from './moduleForms/LeadGenForm';
import PodcastForm from './moduleForms/PodcastForm';
import PostForm from './moduleForms/PostForm';
import QuoteForm from './moduleForms/QuoteForm';
import Settings from './moduleForms/Settings';
import SourceForm from './moduleForms/SourceForm';
import SpacerForm from './moduleForms/SpacerForm';
import TagForm from './moduleForms/TagForm';
import TopicForm from './moduleForms/TopicForm';
import VideoForm from './moduleForms/VideoForm';
import HTMLForm from './moduleForms/HTMLForm';

const Modules = {
  [MODULE_TYPES.SETTINGS]: Settings,
  [MODULE_TYPES.ADD]: AddModule,
  // Videos
  [MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL]: VideoForm,
  [MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL]: VideoForm,
  [MODULE_TYPES.VIDEO_CAROUSEL]: VideoForm,
  [MODULE_TYPES.VIDEO_LIST]: VideoForm,
  [MODULE_TYPES.VIDEO_GRID]: VideoForm,
  [MODULE_TYPES.FEATURED_VIDEO]: VideoForm,
  [MODULE_TYPES.FEATURED_VIDEO_SLIDER]: VideoForm,
  [MODULE_TYPES.VIDEO_PLAYER]: VideoForm,
  [MODULE_TYPES.VIDEO_PLAYER_V2]: VideoForm,
  [MODULE_TYPES.LIVE_VIDEO_PLAYER]: VideoForm,
  // Collections
  [MODULE_TYPES.COLLECTION_CAROUSEL]: CollectionForm,
  [MODULE_TYPES.COLLECTION_GRID]: CollectionForm,
  [MODULE_TYPES.COLLECTION_LIST]: CollectionForm,
  [MODULE_TYPES.FEATURED_COLLECTION]: CollectionForm,
  [MODULE_TYPES.FEATURED_COLLECTION_SLIDER]: CollectionForm,
  [MODULE_TYPES.COLLECTION_ITEM_CAROUSEL]: CollectionItemForm,
  [MODULE_TYPES.COLLECTION_ITEM_GRID]: CollectionItemForm,
  [MODULE_TYPES.COLLECTION_ITEM_LIST]: CollectionItemForm,
  // Contributors
  [MODULE_TYPES.CONTRIBUTOR_CAROUSEL]: ContributorForm,
  [MODULE_TYPES.CONTRIBUTOR_LIST]: ContributorForm,
  [MODULE_TYPES.CONTRIBUTOR_GRID]: ContributorForm,
  [MODULE_TYPES.FEATURED_CONTRIBUTOR]: ContributorForm,
  // Tags
  [MODULE_TYPES.TAG_CAROUSEL]: TagForm,
  [MODULE_TYPES.TAG_CLOUD]: TagForm,
  [MODULE_TYPES.FEATURED_TAG]: TagForm,
  // Topics
  [MODULE_TYPES.TOPIC_CAROUSEL]: TopicForm,
  [MODULE_TYPES.TOPIC_LIST]: TopicForm,
  [MODULE_TYPES.TOPIC_GRID]: TopicForm,
  [MODULE_TYPES.FEATURED_TOPIC]: TopicForm,
  // CTA
  [MODULE_TYPES.CTA_BLOCK]: CTAForm,
  [MODULE_TYPES.CTA_BANNER_WITH_TEXT]: CTAForm,
  [MODULE_TYPES.CTA_BANNER]: CTAForm,
  [MODULE_TYPES.CTA_GRID]: CTAGridCarouselForm,
  [MODULE_TYPES.CTA_CAROUSEL]: CTAGridCarouselForm,
  [MODULE_TYPES.CTA_BUTTON_LIST]: CTAGridCarouselForm,
  [MODULE_TYPES.HERO]: CTAForm,
  // Donation
  [MODULE_TYPES.DONATION]: DonationForm,
  // Copy Heavy
  [MODULE_TYPES.COPY_HEAVY]: CopyHeavyForm,
  [MODULE_TYPES.COPY_HEAVY_HERO]: CopyHeavyForm,
  // Quote
  [MODULE_TYPES.QUOTE]: QuoteForm,
  // Information
  [MODULE_TYPES.INFORMATION]: InformationForm,
  // Lead Gen
  [MODULE_TYPES.LEAD_GEN]: LeadGenForm,
  // Contact Us
  [MODULE_TYPES.CONTACT_US]: LeadGenForm,
  // FAQs
  [MODULE_TYPES.FAQ]: FAQForm,
  // Spacer
  [MODULE_TYPES.SPACER]: SpacerForm,
  // HTML,
  [MODULE_TYPES.HTML_TEXT_BLOCK]: HTMLForm,
  // Podcast
  [MODULE_TYPES.PODCAST_CONTINUE_LISTENING_CAROUSEL]: PodcastForm,
  [MODULE_TYPES.PODCAST_LISTEN_LATER_CAROUSEL]: PodcastForm,
  [MODULE_TYPES.PODCAST_CAROUSEL]: PodcastForm,
  [MODULE_TYPES.PODCAST_LIST]: PodcastForm,
  [MODULE_TYPES.PODCAST_GRID]: PodcastForm,
  [MODULE_TYPES.FEATURED_PODCAST]: PodcastForm,
  [MODULE_TYPES.FEATURED_PODCAST_SLIDER]: PodcastForm,
  [MODULE_TYPES.PODCAST_PLAYER]: PodcastForm,
  // Form
  [MODULE_TYPES.FORM_CAROUSEL]: FormModule,
  [MODULE_TYPES.FORM_DISPLAY]: FormModule,
  // Sources
  [MODULE_TYPES.SOURCE_CAROUSEL]: SourceForm,
  [MODULE_TYPES.SOURCE_LIST]: SourceForm,
  // Article
  [MODULE_TYPES.ARTICLE_LIST]: ArticleForm,
  [MODULE_TYPES.ARTICLE_LIST_V2]: ArticleForm,
  [MODULE_TYPES.ARTICLE_CAROUSEL]: ArticleForm,
  [MODULE_TYPES.FEATURED_ARTICLE]: ArticleForm,
  [MODULE_TYPES.FEATURED_ARTICLE_V2]: ArticleForm,
  [MODULE_TYPES.ARTICLE_CAROUSEL_V2]: ArticleForm,

  // Community
  [MODULE_TYPES.COMMUNITY_CAROUSEL]: CommunityForm,
  [MODULE_TYPES.COMMUNITY_GRID]: CommunityForm,
  [MODULE_TYPES.FEATURED_COMMUNITY]: CommunityForm,
  // Post
  [MODULE_TYPES.POST_CAROUSEL]: PostForm,
  [MODULE_TYPES.POST_LIST]: PostForm,
  [MODULE_TYPES.POST_DETAIL]: PostForm
};

const RightPanel = () => {
  const {
    selectionType,
    form,
    clearRightPanel,
    updateModule,
    deleteModule,
    isDefaultPage,
    pageType
  } = useEditPage();
  const { refetch } = usePagePreview();
  const { id } = useParams();

  const handleCancel = () => {
    if (form.type === FORM_TYPES.ADD) {
      deleteModule(form.index);
    }
    clearRightPanel();
  };

  const handleSuccess = (data) => {
    updateModule(data);
    clearRightPanel();
    refetch();
  };

  if (!selectionType) return <FormLayout />;

  const FormComponent = Modules[selectionType];
  if (!FormComponent) return <FormLayout />;

  if (selectionType === MODULE_TYPES.ADD)
    return (
      <FormLayout>
        <FormComponent />
      </FormLayout>
    );

  return (
    <FormLayout title={PlaceHolderComponents?.[selectionType]?.title}>
      <FormComponent
        key={selectionType}
        onSuccess={handleSuccess}
        onCancel={handleCancel}
        pageId={id}
        form={form}
        type={selectionType}
        isDefaultPage={isDefaultPage}
        pageType={pageType}
      />
    </FormLayout>
  );
};

export default RightPanel;
