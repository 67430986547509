import { Modal } from 'antd';
import React from 'react';
import { ASSET_CATEGORY, ASSET_TYPE } from '../../../common/constants';
import CommonAudioForm from '../audio/CommonAudioForm';
import CommonDocumentForm from '../document/CommonDocumentForm';
import CommonIconForm from '../icons/CommonIconForm';
import CommonImageForm from '../images/CommonImageForm';
import CommonTextForm from '../text/CommonTextForm';
import CommonVideoForm from '../videos/CommonVideoForm';

const CreateAssetModal = ({
  showModal,
  setShowModal,
  assetType,
  title = `Create ${ASSET_TYPE[assetType] ?? 'Asset'}`,
  setNewAsset,
  isMenu
}) => {
  const handleCancel = () => {
    setShowModal(false);
  };
  const renderForms = () => {
    if (assetType === ASSET_CATEGORY.IMAGE) {
      return (
        <CommonImageForm
          isModal
          setShowModal={setShowModal}
          setNewAsset={setNewAsset}
        />
      );
    }
    if (assetType === ASSET_CATEGORY.VIDEO) {
      return (
        <CommonVideoForm
          isModal
          setShowModal={setShowModal}
          setNewAsset={setNewAsset}
        />
      );
    }
    if (assetType === ASSET_CATEGORY.DOCUMENT) {
      return (
        <CommonDocumentForm
          isModal
          setShowModal={setShowModal}
          setNewAsset={setNewAsset}
        />
      );
    }
    if (assetType === ASSET_CATEGORY.AUDIO) {
      return (
        <CommonAudioForm
          isModal
          setShowModal={setShowModal}
          setNewAsset={setNewAsset}
        />
      );
    }
    if (assetType === ASSET_CATEGORY.TEXT) {
      return (
        <CommonTextForm
          isModal
          setShowModal={setShowModal}
          setNewAsset={setNewAsset}
        />
      );
    }
    if (assetType === ASSET_CATEGORY.ICON) {
      return (
        <CommonIconForm
          isModal
          setShowModal={setShowModal}
          setNewAsset={setNewAsset}
          isMenu={isMenu}
        />
      );
    }
  };

  return (
    <Modal
      centered
      open={showModal}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      title={title}
      width="60%"
    >
      <div>{renderForms()}</div>
    </Modal>
  );
};

export default CreateAssetModal;
